import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", {
    staticClass: "header-warp"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_vm._v("新建")])], 1), _c("div", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      border: true
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "id",
      align: "center",
      label: "ID",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "issueNumber",
      align: "center",
      label: "期号",
      "min-width": "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "turntableOpenTime",
      align: "center",
      label: "开始时间",
      "min-width": "160"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "turntableEndTime",
      align: "center",
      label: "结束时间",
      "min-width": "160"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "turntableStartTime",
      align: "center",
      label: "开奖时间",
      "min-width": "160"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "cancellationTime",
      align: "center",
      label: "作废时间",
      "min-width": "160"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "turntableTitle",
      align: "center",
      label: "标题",
      "min-width": "160"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "开奖地址",
      "min-width": "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.province) + _vm._s(scope.row.city) + _vm._s(scope.row.area) + _vm._s(scope.row.township) + _vm._s(scope.row.address) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "turntableNotice",
      align: "center",
      label: "公告",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          on: {
            click: function click($event) {
              return _vm.watchinfo(scope.row);
            }
          }
        }, [_vm._v(" 查看 ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "limitNumber",
      align: "center",
      label: "开奖人数上限",
      "min-width": "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "status",
      align: "center",
      label: "状态",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [scope.row.status === 0 ? _c("el-tag", {
          attrs: {
            type: "info"
          }
        }, [_vm._v("未开始")]) : _vm._e(), scope.row.status === 1 ? _c("el-tag", [_vm._v("参加中")]) : _vm._e(), scope.row.status === 2 ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("准备开奖")]) : _vm._e(), scope.row.status === 3 ? _c("el-tag", {
          attrs: {
            type: "warning"
          }
        }, [_vm._v("已开奖")]) : _vm._e(), scope.row.status === 4 ? _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v("已结束")]) : _vm._e()], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-dropdown", [_c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(scope.row);
            }
          }
        }, [_vm._v(" 编辑"), _c("i", {
          staticClass: "el-icon-arrow-down el-icon--right"
        })]), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          nativeOn: {
            click: function click($event) {
              return _vm.del(scope.row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "text",
            size: "small"
          }
        }, [_vm._v("删除")])], 1), _c("el-dropdown-item", {
          nativeOn: {
            click: function click($event) {
              return _vm.updateStatus(scope.row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "text",
            size: "small"
          }
        }, [_vm._v("修改状态")])], 1), _c("el-dropdown-item", {
          nativeOn: {
            click: function click($event) {
              return _vm.openLotteryDraw(scope.row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "text",
            size: "small"
          }
        }, [_vm._v("开奖")])], 1), _c("el-dropdown-item", {
          nativeOn: {
            click: function click($event) {
              return _vm.openUloadLotteryVideo(scope.row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "text",
            size: "small"
          }
        }, [_vm._v("开奖视频")])], 1), _c("el-dropdown-item", {
          nativeOn: {
            click: function click($event) {
              return _vm.issueCouponsStore(scope.row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "text",
            size: "small"
          }
        }, [_vm._v("商家加券")])], 1), _c("el-dropdown-item", {
          nativeOn: {
            click: function click($event) {
              return _vm.issueCoupons(scope.row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "text",
            size: "small"
          }
        }, [_vm._v("用户加券")])], 1), _c("el-dropdown-item", {
          nativeOn: {
            click: function click($event) {
              return _vm.auditShareCoupons(scope.row);
            }
          }
        }, [_c("el-button", {
          attrs: {
            type: "text",
            size: "small"
          }
        }, [_vm._v("审核分享")])], 1)], 1)], 1)];
      }
    }])
  })], 1), _c("div", {
    staticClass: "mgt20"
  }, [_vm.page.total > 10 ? _c("page", {
    attrs: {
      page: _vm.page
    },
    on: {
      pageChange: _vm.pageChange
    }
  }) : _vm._e()], 1)], 1), _c("el-dialog", {
    attrs: {
      title: _vm.isEdit ? "详情" : "新增",
      visible: _vm.dialogVisible,
      width: "40%",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", [_c("el-form", {
    ref: "redPaper",
    attrs: {
      model: _vm.redPaper,
      "label-width": "120px"
    }
  }, [_c("el-card", [_c("el-form-item", {
    attrs: {
      label: "活动标题"
    }
  }, [_c("el-input", {
    staticClass: "input-warp",
    model: {
      value: _vm.redPaper.turntableTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.redPaper, "turntableTitle", $$v);
      },
      expression: "redPaper.turntableTitle"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "活动时间"
    }
  }, [_c("el-date-picker", {
    staticClass: "input-warp",
    attrs: {
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "datetime",
      placeholder: "选择开始日期时间"
    },
    model: {
      value: _vm.redPaper.turntableOpenTime,
      callback: function callback($$v) {
        _vm.$set(_vm.redPaper, "turntableOpenTime", $$v);
      },
      expression: "redPaper.turntableOpenTime"
    }
  }), _c("el-date-picker", {
    staticClass: "input-warp",
    attrs: {
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "datetime",
      placeholder: "选择结束日期时间"
    },
    model: {
      value: _vm.redPaper.turntableEndTime,
      callback: function callback($$v) {
        _vm.$set(_vm.redPaper, "turntableEndTime", $$v);
      },
      expression: "redPaper.turntableEndTime"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "开奖时间"
    }
  }, [_c("el-date-picker", {
    staticClass: "input-warp",
    attrs: {
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "datetime",
      placeholder: "选择开始日期时间"
    },
    model: {
      value: _vm.redPaper.turntableStartTime,
      callback: function callback($$v) {
        _vm.$set(_vm.redPaper, "turntableStartTime", $$v);
      },
      expression: "redPaper.turntableStartTime"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "奖券作废时间"
    }
  }, [_c("el-date-picker", {
    staticClass: "input-warp",
    attrs: {
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "datetime",
      placeholder: "选择作废日期时间"
    },
    model: {
      value: _vm.redPaper.cancellationTime,
      callback: function callback($$v) {
        _vm.$set(_vm.redPaper, "cancellationTime", $$v);
      },
      expression: "redPaper.cancellationTime"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "开奖地址"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      "value-key": "id",
      "reserve-keyword": "",
      placeholder: "城市+地址关键词",
      "remote-method": _vm.getLocation,
      loading: _vm.locationLoading
    },
    on: {
      change: _vm.locationChange
    },
    model: {
      value: _vm.redPaper.value,
      callback: function callback($$v) {
        _vm.$set(_vm.redPaper, "value", $$v);
      },
      expression: "redPaper.value"
    }
  }, _vm._l(_vm.optionsLocation, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.cityname + item.name,
        value: item
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "单元楼/门牌号"
    }
  }, [_c("el-input", {
    staticClass: "input-warp",
    model: {
      value: _vm.redPaper.address,
      callback: function callback($$v) {
        _vm.$set(_vm.redPaper, "address", $$v);
      },
      expression: "redPaper.address"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "开奖人数上限数量",
      "label-width": "600"
    }
  }, [_c("el-input", {
    staticClass: "input-warp",
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.redPaper.limitNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.redPaper, "limitNumber", $$v);
      },
      expression: "redPaper.limitNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "开奖分段人数上限",
      "label-width": "600"
    }
  }, [_c("el-input", {
    staticClass: "input-warp",
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.redPaper.partLimitNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.redPaper, "partLimitNumber", $$v);
      },
      expression: "redPaper.partLimitNumber"
    }
  })], 1), _c("div", {
    staticClass: "addTip"
  }, [_vm._v("注:开奖分段人数必须能被总人数整除")]), _c("el-form-item", {
    attrs: {
      label: "活动说明"
    }
  }, [_c("Editor", {
    model: {
      value: _vm.redPaper.turntableNotice,
      callback: function callback($$v) {
        _vm.$set(_vm.redPaper, "turntableNotice", $$v);
      },
      expression: "redPaper.turntableNotice"
    }
  })], 1)], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)]), _c("el-dialog", {
    attrs: {
      visible: _vm.statusVisible,
      "before-close": function beforeClose() {
        return _vm.statusVisible = false;
      }
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.statusVisible = $event;
      }
    }
  }, [_c("div", [_c("el-radio-group", {
    model: {
      value: _vm.statusForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.statusForm, "status", $$v);
      },
      expression: "statusForm.status"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("未开始")]), _c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("参加中")]), _c("el-radio", {
    attrs: {
      label: 3
    }
  }, [_vm._v("已开奖")])], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click() {
        return _vm.statusVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.statusSubmit
    }
  }, [_vm._v("确 定")])], 1)]), _c("el-dialog", {
    attrs: {
      visible: _vm.watchVisibel,
      "before-close": function beforeClose() {
        return _vm.watchVisibel = false;
      }
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.watchVisibel = $event;
      }
    }
  }, [_c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.turntableNoticeHtml)
    }
  })]), _c("el-dialog", {
    attrs: {
      visible: _vm.uloadLotteryVisible,
      "before-close": function beforeClose() {
        return _vm.uloadLotteryVisible = false;
      }
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.uloadLotteryVisible = $event;
      }
    }
  }, [_c("div", [_c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: _vm.$uploadImgurl + "/common/file/upload/video",
      data: {
        FoldPath: "上传目录",
        SecretKey: "安全验证"
      },
      "on-progress": _vm.uploadVideoProcess,
      "on-success": _vm.handleVideoSuccess,
      "before-upload": _vm.beforeUploadVideo,
      "show-file-list": false
    }
  }, [_vm.videoForm.turntableOpenVideoUrl != "" && !_vm.videoFlag ? _c("video", {
    staticClass: "avatar video-avatar",
    attrs: {
      src: _vm.videoForm.turntableOpenVideoUrl,
      controls: "controls",
      width: "420px"
    }
  }, [_vm._v(" 您的浏览器不支持视频播放 ")]) : _vm.videoForm.turntableOpenVideoUrl == "" && !_vm.videoFlag ? _c("i", {
    staticClass: "el-icon-plus avatar-uploader-icon"
  }) : _vm._e(), _vm.videoFlag == true ? _c("el-progress", {
    staticStyle: {
      "margin-top": "7px"
    },
    attrs: {
      type: "circle",
      percentage: _vm.videoUploadPercent
    }
  }) : _vm._e()], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click() {
        return _vm.uloadLotteryVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.videoSubmit
    }
  }, [_vm._v("确 定")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };