import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * The file enables `@/store/index.js` to import all vuex modules
 * in a one-shot manner. There should not be any reason to edit this file.
 */

var files = require.context('./modules', false, /\.js$/);
var modules = {};
files.keys().forEach(function (key) {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
});
export default {
  namespaced: true,
  modules: modules
};