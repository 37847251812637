import "core-js/modules/es.number.constructor.js";
export default {
  name: 'viewlist',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    datalist: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    page: {
      type: Object,
      default: function _default() {}
    },
    str: {
      type: Number
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit('viewClose');
    },
    pageChange: function pageChange(item) {
      this.$emit('pageChange', item);
    }
  }
};