var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-circle-plus-outline"
    },
    on: {
      click: _vm.handleAddRow
    }
  }, [_vm._v("新增")])], 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.datainfo,
      stripe: "",
      height: _vm.$clientHeight
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center",
      label: "序号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "version",
      align: "center",
      label: "版本"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "lowestVersion",
      align: "center",
      label: "支持最低版本"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "isForce",
      align: "center",
      label: "是否强制\t"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.isForce ? "是" : "否") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "isApk",
      align: "center",
      label: "是否是整包更新"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.isApk ? "是" : "否") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "updateUrl",
      align: "center",
      label: "下载地址\t"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.updateUrl) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "updateInfo",
      align: "center",
      label: "更新内容信息\t"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      align: "center",
      label: "创建时间\t"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return scope.row.updateTime ? [_vm._v(" " + _vm._s(scope.row.createTime.substring(0, 10)) + " ")] : undefined;
      }
    }], null, true)
  }), _c("el-table-column", {
    attrs: {
      prop: "updateTime",
      align: "center",
      label: "更新时间\t"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return scope.row.updateTime ? [_vm._v(" " + _vm._s(scope.row.updateTime.substring(0, 10)) + " ")] : undefined;
      }
    }], null, true)
  })], 1), _c("div", {
    staticClass: "mgt20"
  }, [_vm.page.total > 10 ? _c("page", {
    attrs: {
      page: _vm.page
    },
    on: {
      pageChange: _vm.pageChange
    }
  }) : _vm._e()], 1), _c("div", [_c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.addclose,
      visible: _vm.addDialogVisible,
      title: _vm.dialogTitle,
      width: "450px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData,
      size: "small",
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "版本号",
      prop: "version"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请填写"
    },
    model: {
      value: _vm.formData.version,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "version", $$v);
      },
      expression: "formData.version"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "是否强制更新",
      prop: "isForce"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      size: "mini",
      clearable: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.isForce,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "isForce", $$v);
      },
      expression: "formData.isForce"
    }
  }, [_c("el-option", {
    attrs: {
      label: "是",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "否",
      value: false
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "是否整包更新\t",
      prop: "isApk"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      size: "mini",
      clearable: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.isApk,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "isApk", $$v);
      },
      expression: "formData.isApk"
    }
  }, [_c("el-option", {
    attrs: {
      label: "是",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "否",
      value: false
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "最低版本号\t",
      prop: "lowestVersion"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "样例为（1.2.1）这样"
    },
    model: {
      value: _vm.formData.lowestVersion,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "lowestVersion", $$v);
      },
      expression: "formData.lowestVersion"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "适用平台",
      prop: "platform"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      size: "mini",
      clearable: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.platform,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "platform", $$v);
      },
      expression: "formData.platform"
    }
  }, [_c("el-option", {
    attrs: {
      label: "安卓",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "ios",
      value: 2
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "更新信息",
      prop: "updateInfo"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请填写"
    },
    model: {
      value: _vm.formData.updateInfo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "updateInfo", $$v);
      },
      expression: "formData.updateInfo"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "上传安装包地址",
      prop: "updateUrl",
      type: "number"
    }
  }, [_c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      action: _vm.$uploadImgurl + "/common/file/upload/apk",
      accept: "apk",
      "on-success": _vm.handleAvatarSuccessimage,
      "file-list": _vm.fileList
    }
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    }
  }, [_vm._v("点击上传")]), _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  }, [_vm._v("只能上传apk文件")])], 1)], 1)], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      size: "mini",
      type: "primary",
      disabled: _vm.isOkUpload
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("保存")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      size: "mini",
      type: "info"
    },
    on: {
      click: _vm.addclose
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };