var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-circle-plus-outline"
    },
    on: {
      click: _vm.handleAddRow
    }
  }, [_vm._v("新增")])], 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.datainfo,
      border: true
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "id",
      align: "center",
      label: "ID",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "src",
      align: "center",
      label: "图片"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.src ? _c("el-image", {
          staticStyle: {
            width: "90px",
            height: "50px"
          },
          attrs: {
            "z-index": 4000,
            src: scope.row.src,
            "preview-src-list": [scope.row.src]
          }
        }) : _c("div", [_vm._v("暂无图片")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "title",
      align: "center",
      label: "名称",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "price",
      align: "center",
      label: "价格",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "类型",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [scope.row.type == 1 ? _c("div", [_vm._v("论坛礼物")]) : _vm._e(), scope.row.type == 2 ? _c("div", [_vm._v("直播礼物")]) : _vm._e()])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "giftOrder",
      align: "center",
      label: "排序(数字越大越靠前)",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作",
      width: "270"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "warning",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.edit(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.del(scope.row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1)], 1), _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      visible: _vm.addDialogVisible,
      title: _vm.dialogTitle,
      width: "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData,
      size: "small",
      "label-width": "100px",
      rules: _vm.addrules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "名称",
      prop: "title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "价格(牛币)",
      prop: "price"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.price,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "price", $$v);
      },
      expression: "formData.price"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "图片宽度(px)",
      prop: "width"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.width,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "width", $$v);
      },
      expression: "formData.width"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "图片高度(px)",
      prop: "height"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.height,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "height", $$v);
      },
      expression: "formData.height"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "图片",
      prop: "src"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "avatar-uploader",
    attrs: {
      action: _vm.$uploadImgurl + "/common/file/upload",
      "show-file-list": false,
      "on-success": _vm.handleAvatarSuccessimage
    }
  }, [_vm.formData.src ? _c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.formData.src,
      width: "120px"
    }
  }) : _c("i", {
    staticClass: "el-icon-plus avatar-uploader-icon"
  })])], 1), _c("el-form-item", {
    attrs: {
      label: "分类类型",
      prop: "type"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择分类类型"
    },
    model: {
      value: _vm.formData.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "type", $$v);
      },
      expression: "formData.type"
    }
  }, [_c("el-option", {
    attrs: {
      label: "论坛礼物",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "直播礼物",
      value: 2
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "排序",
      prop: "giftOrder"
    }
  }, [_c("el-input-number", {
    staticClass: "inputnumber",
    attrs: {
      min: 1,
      max: 999
    },
    model: {
      value: _vm.formData.giftOrder,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "giftOrder", $$v);
      },
      expression: "formData.giftOrder"
    }
  })], 1)], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("保存")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      size: "mini",
      type: "info"
    },
    on: {
      click: function click($event) {
        _vm.addDialogVisible = false;
      }
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };