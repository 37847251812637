import request from '../request/index';
import { jsonToFormdata } from '../request/tools';
// 获取字典data列表
export var adminAreaADD = function adminAreaADD() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/adminArea/add',
    method: 'post',
    data: data
  });
};
export var adminAreaList = function adminAreaList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/adminArea/list',
    method: 'get',
    data: data
  });
};
export var adminAreaDel = function adminAreaDel() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  // console.log(data)
  return request({
    url: "/adminArea/del/".concat(id),
    method: 'delete'
  });
};
export var roleList = function roleList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/role/list",
    method: 'get',
    data: data
  });
};
export var allMenu = function allMenu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/permission/admin/menu/tree",
    method: 'get',
    data: data
  });
};
export var addMenu = function addMenu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/permission/add",
    method: 'post',
    data: data
  });
};
export var addRole = function addRole() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/role/add",
    method: 'post',
    data: data
  });
};
export var updateRole = function updateRole() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/role/update",
    method: 'put',
    data: data
  });
};
export var rolePermissionList = function rolePermissionList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/role/role/permission/list",
    method: 'get',
    data: data
  });
};
export var adminMenu = function adminMenu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/permission/admin/menu/tree",
    method: 'get',
    data: data
  });
};
export var adminAdd = function adminAdd() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/admin/add",
    method: 'post',
    data: data
  });
};
export var updateAdminPassword = function updateAdminPassword() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/admin/change/password",
    method: 'post',
    data: data
  });
};
export var adminList = function adminList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/admin/list",
    method: 'get',
    data: data
  });
};
export var adminAreaListFilter = function adminAreaListFilter() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/adminArea/list",
    method: 'get',
    data: data
  });
};
export var updateMenu = function updateMenu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/permission/update",
    method: 'put',
    data: data
  });
};
export var destroyMenu = function destroyMenu() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  // console.log(data)
  return request({
    url: "/permission/delete/".concat(id),
    method: 'delete'
  });
};
export var roleDelete = function roleDelete() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  // console.log(data)
  return request({
    url: "/role/delete/".concat(id),
    method: 'delete'
  });
};
export var adminInfo = function adminInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/admin/info",
    method: 'post',
    data: data
  });
};
export var adminChangeInfo = function adminChangeInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/admin/change/info",
    method: 'post',
    data: data
  });
};