import request from '../request/index';
import { jsonToFormdata } from '../request/tools';

//获取骑手申请列表
export var getServiceList = function getServiceList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data=jsonToFormdata(data)
  return request({
    url: '/user/list',
    method: 'get',
    data: data
  });
};