import request from '../request/index';

// 获取字典data列表
export var getAffairsFormuList = function getAffairsFormuList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumGovernmentInfo/applyList',
    method: 'post',
    data: data
  });
};
// 获取字典data列表
export var getAffairsApplyList = function getAffairsApplyList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumGovPostUser/list',
    method: 'get',
    data: data
  });
};

// 审核文章结果
export var editAffairsFormu = function editAffairsFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumGovernmentInfo/apply',
    method: 'post',
    data: data
  });
};

// 审核文章结果
export var editAffairsApply = function editAffairsApply() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumGovPostUser/applyPass',
    method: 'post',
    data: data
  });
};

// 上架文章
export var uploadAffairsFormu = function uploadAffairsFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumGovernmentInfo/changeOnline',
    method: 'post',
    data: data
  });
};

//修改骑手申请 
export var freeseAffairs = function freeseAffairs() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data=jsonToFormdata(data)
  return request({
    url: '/forumGovPostUser/edit',
    method: 'put',
    data: data
  });
};