import request from '../request/index';

// 获取字典data列表
export var getStudyFormuList = function getStudyFormuList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumStudyInfo/applyList',
    method: 'post',
    data: data
  });
};
export var forumBookChapterApplyList = function forumBookChapterApplyList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumBookChapter/applyList',
    method: 'post',
    data: data
  });
};

// 审核文章结果
export var editStudyFormu = function editStudyFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumStudyInfo/apply',
    method: 'post',
    data: data
  });
};

// 上架文章
export var uploadStudyFormu = function uploadStudyFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumStudyInfo/changeOnline',
    method: 'post',
    data: data
  });
};
export var autoComment = function autoComment() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumUserComment/autoComment',
    method: 'post',
    data: data
  });
};
export var forumBookChapterApply = function forumBookChapterApply() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumBookChapter/apply',
    method: 'post',
    data: data
  });
};