import request from '../request/index';

//post  params
export var loginPost = function loginPost() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  console.log("inner login");
  return request({
    url: 'admin/admin/login',
    method: 'post',
    data: data
  });
};
export var getUserInfo = function getUserInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var Auth = arguments.length > 1 ? arguments[1] : undefined;
  console.log("inner userinfo");
  return request({
    url: 'admin/info',
    method: 'post',
    data: data,
    headers: {
      Auth: Auth
    }
  });
};

//上传图片
export var uploadImg = function uploadImg() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var Auth = arguments.length > 1 ? arguments[1] : undefined;
  return request({
    url: 'common/file/upload',
    method: 'post',
    data: data,
    headers: {
      Auth: Auth
    }
  });
};

//获取公告列表
export var getAnnouncementList = function getAnnouncementList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var Auth = arguments.length > 1 ? arguments[1] : undefined;
  return request({
    url: 'message/list',
    method: 'post',
    data: data,
    headers: {
      Auth: Auth
    }
  });
};

//获取公告列表
export var addAnnouncementInfo = function addAnnouncementInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var Auth = arguments.length > 1 ? arguments[1] : undefined;
  return request({
    url: 'message/add',
    method: 'post',
    data: data,
    headers: {
      Auth: Auth
    }
  });
};