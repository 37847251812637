import request from '../request/index';

// 用户列表
export var userList = function userList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/user/list',
    method: 'get',
    data: data
  });
};
//根据用户id来获取牛币列表
export var getNiuBiInfoListById = function getNiuBiInfoListById() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data=jsonToFormdata(data)
  return request({
    url: '/user/wallet/detailed/list/admin',
    method: 'get',
    data: data
  });
};

//查看赊账用户列表
export var getCreditList = function getCreditList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data=jsonToFormdata(data)
  return request({
    url: '/user/userCreditInfo/list',
    method: 'get',
    data: data
  });
};

//删除赊账用户
export var delCredit = function delCredit(id) {
  // data=jsonToFormdata(data)
  return request({
    url: '/user/userCreditInfo/del/' + id,
    method: 'delete'
  });
};

// 用户列表
export var virtualUserList = function virtualUserList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/userVirtual/list',
    method: 'post',
    data: data
  });
};

// add用户
export var addvirtualUser = function addvirtualUser() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/userVirtual/add',
    method: 'post',
    data: data
  });
};

// edit用户
export var editvirtualUser = function editvirtualUser() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/userVirtual/edit',
    method: 'put',
    data: data
  });
};

// del用户
export var delvirtualUser = function delvirtualUser(id) {
  return request({
    url: '/userVirtual/del/' + id,
    method: 'delete'
  });
};

// 虚拟用户文案列表
export var virtualUserMessageList = function virtualUserMessageList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/userVirtualContent/list',
    method: 'post',
    data: data
  });
};

//add 虚拟用户文案
export var addvirtualUserMessage = function addvirtualUserMessage() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/userVirtualContent/add',
    method: 'post',
    data: data
  });
};

// edit虚拟用户文案
export var editvirtualUserMessage = function editvirtualUserMessage() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/userVirtualContent/edit',
    method: 'put',
    data: data
  });
};

// del虚拟用户文案
export var delvirtualUserMessage = function delvirtualUserMessage(id) {
  return request({
    url: '/userVirtualContent/del/' + id,
    method: 'delete'
  });
};