import _objectSpread from "E:/code/mn-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: _objectSpread(_objectSpread({}, mapGetters('d2admin', {
    logLength: 'log/length',
    logLengthError: 'log/lengthError'
  })), {}, {
    tooltipContent: function tooltipContent() {
      return this.logLength === 0 ? '没有日志或异常' : "".concat(this.logLength, " \u6761\u65E5\u5FD7").concat(this.logLengthError > 0 ? " | \u5305\u542B ".concat(this.logLengthError, " \u4E2A\u5F02\u5E38") : '');
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations('d2admin/log', ['clean'])), {}, {
    handleClick: function handleClick() {
      this.$router.push({
        name: 'log'
      });
    }
  })
};