import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("header", {
    staticClass: "head"
  }, [_c("el-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("el-button", {
    attrs: {
      type: "warning",
      size: "small"
    },
    on: {
      click: _vm.addRole
    }
  }, [_vm._v("新增")])], 1)], 1)], 1), _c("main", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "main"
  }, [_c("ux-grid", {
    ref: "grid",
    attrs: {
      border: "",
      height: _vm.$clientHeight,
      size: "mini",
      stripe: ""
    }
  }, [_c("ux-table-column", {
    attrs: {
      field: "id",
      title: "ID",
      width: "70"
    }
  }), _c("ux-table-column", {
    attrs: {
      field: "account",
      title: "账号",
      "min-width": "180",
      resizable: ""
    }
  }), _c("ux-table-column", {
    attrs: {
      field: "name",
      title: "用户名",
      "min-width": "180",
      resizable: ""
    }
  }), _c("ux-table-column", {
    attrs: {
      field: "phone",
      title: "手机号",
      "min-width": "180",
      resizable: ""
    }
  }), _c("ux-table-column", {
    attrs: {
      field: "adminType",
      title: "区域级别",
      "min-width": "180",
      resizable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [scope.row.adminType === 0 ? _c("div", [_vm._v("超级管理员")]) : _vm._e(), scope.row.adminType === 1 ? _c("div", [_vm._v("区域代理")]) : _vm._e(), scope.row.adminType === 2 ? _c("div", [_vm._v("区域管理员")]) : _vm._e()])];
      }
    }])
  }), _c("ux-table-column", {
    attrs: {
      field: "phone",
      title: "区域",
      "min-width": "180",
      resizable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.filterArea(scope.row.allArea)))])];
      }
    }])
  }), _c("ux-table-column", {
    attrs: {
      field: "phone",
      title: "角色",
      "min-width": "180",
      resizable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.filterRole(scope.row.allRole)))])];
      }
    }])
  }), _c("ux-table-column", {
    attrs: {
      field: "createTime",
      title: "创建时间",
      "min-width": "180",
      resizable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.createTime))])];
      }
    }])
  }), _c("ux-table-column", {
    attrs: {
      field: "updateTime",
      title: "更新时间",
      "min-width": "180",
      resizable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.updateTime))])];
      }
    }])
  }), _c("ux-table-column", {
    attrs: {
      title: "操作",
      width: "250",
      resizable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.updataRole(scope.row, "update");
            }
          }
        }, [_vm._v("修改账户")]), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.updataRole(scope.row, "updatePWD");
            }
          }
        }, [_vm._v("修改密码")]), _c("el-popover", {
          staticClass: "del-popover",
          attrs: {
            placement: "bottom",
            width: "240",
            trigger: "click"
          },
          model: {
            value: scope.row.visible,
            callback: function callback($$v) {
              _vm.$set(scope.row, "visible", $$v);
            },
            expression: "scope.row.visible"
          }
        }, [_c("p", [_vm._v("你正在执行删除操作，确定删除吗？")]), _c("div", {
          staticStyle: {
            "text-align": "right",
            margin: "0"
          }
        }, [_c("el-button", {
          attrs: {
            size: "mini",
            type: "text"
          },
          on: {
            click: function click($event) {
              scope.row.visible = false;
            }
          }
        }, [_vm._v("取消")]), _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            loading: scope.row.delLoading
          },
          on: {
            click: function click($event) {
              return _vm.del(scope.row);
            }
          }
        }, [_vm._v("确定")])], 1)])], 1)];
      }
    }])
  })], 1)], 1), _c("article", [_c("el-dialog", {
    attrs: {
      title: _vm.addRoleFormType == "add" ? "添加账号" : "修改",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "add-role-form-warp"
  }, [_c("el-form", {
    ref: "addRoleForm",
    staticClass: "add-role-form",
    attrs: {
      model: _vm.addRoleForm,
      "label-width": "80px"
    }
  }, [_vm.addRoleFormType !== "updatePWD" ? _c("el-form-item", {
    attrs: {
      label: "账号"
    }
  }, [_c("el-input", {
    attrs: {
      "aria-atomic": "false"
    },
    model: {
      value: _vm.addRoleForm.account,
      callback: function callback($$v) {
        _vm.$set(_vm.addRoleForm, "account", $$v);
      },
      expression: "addRoleForm.account"
    }
  })], 1) : _vm._e(), _vm.addRoleFormType !== "update" ? _c("el-form-item", {
    attrs: {
      label: "密码"
    }
  }, [_c("el-input", {
    attrs: {
      "aria-atomic": "false",
      type: "password"
    },
    model: {
      value: _vm.addRoleForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.addRoleForm, "password", $$v);
      },
      expression: "addRoleForm.password"
    }
  })], 1) : _vm._e(), _vm.addRoleFormType !== "updatePWD" ? _c("el-form-item", {
    attrs: {
      label: "用户名"
    }
  }, [_c("el-input", {
    attrs: {
      "aria-atomic": "false"
    },
    model: {
      value: _vm.addRoleForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.addRoleForm, "name", $$v);
      },
      expression: "addRoleForm.name"
    }
  })], 1) : _vm._e(), _vm.addRoleFormType !== "updatePWD" ? _c("el-form-item", {
    attrs: {
      label: "手机号"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.addRoleForm.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.addRoleForm, "phone", $$v);
      },
      expression: "addRoleForm.phone"
    }
  })], 1) : _vm._e(), _vm.addRoleFormType !== "updatePWD" ? _c("el-form-item", {
    attrs: {
      label: "区域"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: ""
    },
    model: {
      value: _vm.addRoleForm.allArea,
      callback: function callback($$v) {
        _vm.$set(_vm.addRoleForm, "allArea", $$v);
      },
      expression: "addRoleForm.allArea"
    }
  }, _vm._l(_vm.areaOption, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        value: item.id,
        label: item.province + item.city + item.area,
        disabled: item.selectedIs
      }
    });
  }), 1)], 1) : _vm._e(), _vm.addRoleFormType !== "updatePWD" ? _c("el-form-item", {
    attrs: {
      label: "角色"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: ""
    },
    model: {
      value: _vm.addRoleForm.allRole,
      callback: function callback($$v) {
        _vm.$set(_vm.addRoleForm, "allRole", $$v);
      },
      expression: "addRoleForm.allRole"
    }
  }, _vm._l(_vm.roleOption, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        value: item.id,
        label: item.name
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };