import "core-js/modules/es.object.to-string.js";
import axios from 'axios';
import * as tools from './tools';
import util from '@/libs/util.js';
import { Message, MessageBox } from 'element-ui';
// import util from '@/libs/util.js'
import { setLocalStorage, getLocalStorage, removeLocalStorage } from '@/libs/util.localStorage';
// 创建axios
export var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true, // send cookies when cross-domain requests
  // headers:{
  //   Auth:util.cookies.get('token')
  // },
  timeout: 60000 * 5 // request timeout 5 分钟
});

// 请求拦截
service.interceptors.request.use(function (config) {
  if (config.method === 'get') {
    config.params = config.data;
    delete config.data;
  }

  // console.log(config.params,'111111111')
  var token = getLocalStorage('token');
  if (token) {
    config.headers.Auth = "".concat(token);
  }
  return config;
}, function (error) {
  // do something with request error

  console.log(error); // for debug
  return Promise.reject(error);
});
// 响应拦截
service.interceptors.response.use(function (response) {
  var res = response.data;
  if (res.code === 200) {
    return res;
  } else {
    return tools.responseError(res);
  }
}, function (error) {
  Message({
    message: error,
    type: 'error',
    duration: 5 * 1000
  });
  tools.errorLog(error);
  return Promise.reject(error);
});
export default service;