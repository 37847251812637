import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-circle-plus-outline"
    },
    on: {
      click: _vm.handleAddRow
    }
  }, [_vm._v("新增")]), _c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-refresh"
    },
    on: {
      click: function click($event) {
        return _vm.handleCheck();
      }
    }
  }, [_vm._v("更新")])], 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.datainfo,
      border: "",
      stripe: "",
      height: _vm.$clientHeight
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center",
      label: "序号",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "title",
      align: "center",
      label: "视频标题"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalNum",
      align: "center",
      label: "总可观看次数"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "watchNum",
      align: "center",
      label: "已观看次数"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "oncePrice",
      align: "center",
      label: "单价"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "videoUrl",
      align: "center",
      label: "总价"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s((scope.row.oncePrice * scope.row.totalNum).toFixed(2)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      align: "center",
      label: "发布时间"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "videoUrl",
      align: "center",
      label: "视频地址"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "warning",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.videoView(scope.row);
            }
          }
        }, [_vm._v("查看")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "homeUrl",
      align: "center",
      label: "封面图片"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.homeUrl ? _c("el-image", {
          staticStyle: {
            width: "90px",
            height: "50px"
          },
          attrs: {
            "z-index": 4000,
            src: scope.row.homeUrl,
            "preview-src-list": [scope.row.homeUrl]
          }
        }) : _c("div", [_vm._v("暂无图片")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "isShow",
      align: "center",
      label: "显示状态",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-switch", {
          attrs: {
            "active-value": true,
            "inactive-value": false,
            "active-color": "#1890ff",
            "inactive-color": "#dcdfe6",
            "active-text": "显示",
            "inactive-text": "隐藏"
          },
          on: {
            change: function change($event) {
              return _vm.handleChangeStatus(scope.row, scope.row.isShow);
            }
          },
          model: {
            value: scope.row.isShow,
            callback: function callback($$v) {
              _vm.$set(scope.row, "isShow", $$v);
            },
            expression: "scope.row.isShow"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "isShow",
      align: "center",
      label: "审核状态",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [scope.row.state == 0 ? _c("div", [_vm._v("待审核")]) : _vm._e(), scope.row.state == 1 ? _c("div", [_vm._v("已拒绝")]) : _vm._e(), scope.row.state == 2 ? _c("div", [_vm._v("已审核")]) : _vm._e()])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作",
      width: "280"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.state != 0 ? _c("el-button", {
          attrs: {
            type: "info",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.seeclick(scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), scope.row.state == 0 ? _c("el-button", {
          attrs: {
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.reviewclick(scope.row);
            }
          }
        }, [_vm._v("审核")]) : _vm._e(), scope.row.state == 2 ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.virtualWatch(scope.row);
            }
          }
        }, [_vm._v("虚拟观看次数")]) : _vm._e()];
      }
    }])
  })], 1), _c("div", {
    staticClass: "mgt20"
  }, [_vm.page.total > 10 ? _c("page", {
    attrs: {
      page: _vm.page
    },
    on: {
      pageChange: _vm.pageChange
    }
  }) : _vm._e()], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.virtualWatchDialogVisible,
      title: "虚拟观看次数"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.virtualWatchDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "virtualWatch",
    attrs: {
      model: _vm.editFormData,
      "label-width": "110px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "已虚拟观看次数",
      prop: "totalNum",
      type: "number"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.virtualWatchData.num,
      callback: function callback($$v) {
        _vm.$set(_vm.virtualWatchData, "num", $$v);
      },
      expression: "virtualWatchData.num"
    }
  })], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.virtualWatchSubmitForm
    }
  }, [_vm._v("确定")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      type: "info"
    },
    on: {
      click: function click($event) {
        _vm.virtualWatchDialogVisible = false;
      }
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1), _c("el-dialog", {
    attrs: {
      width: "500px",
      title: "视频",
      visible: _vm.videovisible,
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.videovisible = $event;
      }
    }
  }, [_vm.videovisible ? _c("el-form", {
    ref: "form",
    attrs: {
      "label-width": "55px"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "clientName"
    }
  }, [_c("video", {
    ref: "vidoe",
    attrs: {
      width: "350",
      height: "300",
      controls: ""
    }
  }, [_c("source", {
    attrs: {
      src: _vm.selecthandlist.videoUrl,
      type: "video/mp4"
    }
  })])])], 1) : _vm._e()], 1), _c("div", [_c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.handleCloseAdd,
      visible: _vm.addDialogVisible,
      title: _vm.dialogTitle,
      width: "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      size: "small",
      "label-width": "110px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "标题",
      prop: "title",
      type: "number"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "距离",
      prop: "distance",
      type: "text"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.distance,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "distance", $$v);
      },
      expression: "formData.distance"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "问题",
      prop: "question",
      type: "text"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.question,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "question", $$v);
      },
      expression: "formData.question"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "答案A",
      prop: "question",
      type: "text"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.qa,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "qa", $$v);
      },
      expression: "formData.qa"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "答案B",
      prop: "question",
      type: "text"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.qb,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "qb", $$v);
      },
      expression: "formData.qb"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "答案C",
      prop: "question",
      type: "text"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.qc,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "qc", $$v);
      },
      expression: "formData.qc"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "答案D",
      prop: "question",
      type: "text"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.qd,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "qd", $$v);
      },
      expression: "formData.qd"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "正确答案",
      prop: "answer"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择正确答案"
    },
    model: {
      value: _vm.formData.answer,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "answer", $$v);
      },
      expression: "formData.answer"
    }
  }, [_c("el-option", {
    attrs: {
      label: "A",
      value: "A"
    }
  }, [_vm._v("A")]), _c("el-option", {
    attrs: {
      label: "B",
      value: "B"
    }
  }, [_vm._v("B")]), _c("el-option", {
    attrs: {
      label: "C",
      value: "C"
    }
  }, [_vm._v("C")]), _c("el-option", {
    attrs: {
      label: "D",
      value: "D"
    }
  }, [_vm._v("D")])], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "视频",
      prop: "videoUrl",
      type: "number"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: _vm.$uploadImgurl + "/common/file/upload/video",
      data: {
        FoldPath: "上传目录",
        SecretKey: "安全验证"
      },
      "on-progress": _vm.uploadVideoProcess,
      "on-success": _vm.handleVideoSuccess,
      "before-upload": _vm.beforeUploadVideo,
      "show-file-list": false
    }
  }, [_vm.formData.videoUrl != "" && !_vm.videoFlag ? _c("video", {
    staticClass: "avatar video-avatar",
    attrs: {
      src: _vm.formData.videoUrl,
      controls: "controls",
      width: "120px"
    }
  }, [_vm._v(" 您的浏览器不支持视频播放 ")]) : _vm.formData.videoUrl == "" && !_vm.videoFlag ? _c("i", {
    staticClass: "el-icon-plus avatar-uploader-icon"
  }) : _vm._e(), _vm.videoFlag == true ? _c("el-progress", {
    staticStyle: {
      "margin-top": "7px"
    },
    attrs: {
      type: "circle",
      percentage: _vm.videoUploadPercent
    }
  }) : _vm._e()], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "封面图片",
      prop: "homeUrl",
      type: "number"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "avatar-uploader",
    attrs: {
      action: _vm.$uploadImgurl + "/common/file/upload",
      "show-file-list": false,
      "on-success": _vm.handleAvatarSuccessimage
    }
  }, [_vm.formData.homeUrl ? _c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.formData.homeUrl,
      width: "120px"
    }
  }) : _c("i", {
    staticClass: "el-icon-plus avatar-uploader-icon"
  })])], 1)], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitAdd
    }
  }, [_vm._v("确定")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      size: "mini",
      type: "info"
    },
    on: {
      click: _vm.handleCloseAdd
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1), _c("div", [_c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.handleCloseSee,
      visible: _vm.seeDialogVisible,
      title: _vm.dialogTitle,
      width: "550px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.seeDialogVisible = $event;
      }
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.seeFormData,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center",
      label: "序号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userName",
      align: "center",
      label: "观看用户名称"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userPhone",
      align: "center",
      label: "手机号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      align: "center",
      label: "观看时间"
    }
  })], 1)], 1)], 1), _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.handleCloseReview,
      visible: _vm.reviewDialogVisible,
      title: _vm.dialogTitle,
      width: "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.reviewDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "reviewFormData",
    attrs: {
      model: _vm.reviewFormData,
      rules: _vm.rules,
      size: "small",
      "label-width": "130px"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      label: "审核结果",
      prop: "value"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.changereview
    },
    model: {
      value: _vm.reviewFormData.value,
      callback: function callback($$v) {
        _vm.$set(_vm.reviewFormData, "value", $$v);
      },
      expression: "reviewFormData.value"
    }
  }, [_c("el-option", {
    attrs: {
      label: "通过",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "不通过",
      value: false
    }
  })], 1)], 1), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    attrs: {
      label: "可观看次数",
      prop: "Num",
      type: "number"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.reviewFormData.Num,
      callback: function callback($$v) {
        _vm.$set(_vm.reviewFormData, "Num", $$v);
      },
      expression: "reviewFormData.Num"
    }
  })], 1), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visibletextarea,
      expression: "visibletextarea"
    }],
    attrs: {
      label: "审批意见",
      prop: "textarea"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "400px"
    },
    attrs: {
      type: "textarea",
      placeholder: "请输入审核意见......",
      maxlength: 100,
      rows: 5
    },
    on: {
      input: _vm.inputchange
    },
    model: {
      value: _vm.reviewFormData.textarea,
      callback: function callback($$v) {
        _vm.$set(_vm.reviewFormData, "textarea", $$v);
      },
      expression: "reviewFormData.textarea"
    }
  })], 1)], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.reviewSubmitForm
    }
  }, [_vm._v("确定")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      size: "mini",
      type: "info"
    },
    on: {
      click: _vm.handleCloseReview
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };