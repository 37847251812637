var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.tableData,
      stripe: "",
      height: _vm.$clientHeight
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center",
      label: "序号",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "num",
      label: "牛币数量",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "明细类型"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.type == 1 ? "增加" : "减少"))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "message",
      label: "变动信息"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建日期"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return scope.row.createTime ? [_vm._v(" " + _vm._s(scope.row.createTime.substring(0, 10)) + " ")] : undefined;
      }
    }], null, true)
  }), _c("el-table-column", {
    attrs: {
      prop: "updateTime",
      label: "更新日期"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return scope.row.updateTime ? [_vm._v(" " + _vm._s(scope.row.updateTime.substring(0, 10)) + " ")] : undefined;
      }
    }], null, true)
  })], 1), _c("div", {
    staticClass: "mgt20"
  }, [_vm.page.total > 10 ? _c("page", {
    attrs: {
      page: _vm.page
    },
    on: {
      pageChange: _vm.pageChange
    }
  }) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };