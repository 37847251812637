import request from '../request/index';
import { jsonToFormdata } from '../request/tools';

// 获取同城新事内的文章分类列表 formData get 
export var articleCategoryList = function articleCategoryList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/forum/category/list/to/manage',
    method: 'get',
    data: data
  });
};

// 修改文章分类  json put 
export var editCategory = function editCategory() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/forum/category/edit',
    method: 'put',
    data: data
  });
};

// 添加文章分类  json post 
export var addCategory = function addCategory() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/forum/category/add',
    method: 'post',
    data: data
  });
};
// 删除文章分类  json post 
export var delCategory = function delCategory(id) {
  return request({
    url: '/forum/category/del/' + id,
    method: 'delete'
  });
};

//获取文章列表
export var articleList = function articleList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumHouseInfo/applyList',
    method: 'post',
    data: data
  });
};

// 
// export const articleList = (data = {}) => {
//   return request({
//     url: '/forum/list/to/manage',
//     method: 'get',
//     data
//   })
// }

//新增文章  post json
export var addArticle = function addArticle() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/forum/add',
    method: 'post',
    data: data
  });
};

//删除文章  post json
export var delArticle = function delArticle(id) {
  return request({
    url: '/forum/del/' + id,
    method: 'delete'
  });
};

//文章点赞列表   
export var getArticlePointList = function getArticlePointList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/forum/like/list',
    method: 'get',
    data: data
  });
};
//文章打赏列表   
export var getArticleRewardList = function getArticleRewardList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/forum/reward/list',
    method: 'get',
    data: data
  });
};

//文章评论列表   
export var getArticleCommentList = function getArticleCommentList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/forum/comment/list/to/manage',
    method: 'get',
    data: data
  });
};

//删除文章评论列表   
export var delArticleComment = function delArticleComment(id) {
  return request({
    url: '/forum/comment/del/' + id,
    method: 'delete'
  });
};