var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("header", {
    staticClass: "head"
  }, [_c("el-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("el-button", {
    attrs: {
      type: "warning",
      size: "small"
    },
    on: {
      click: _vm.addRole
    }
  }, [_vm._v("新增")])], 1)], 1)], 1), _c("main", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "main"
  }, [_c("ux-grid", {
    ref: "grid",
    attrs: {
      border: "",
      height: _vm.$clientHeight,
      size: "mini",
      stripe: ""
    }
  }, [_c("ux-table-column", {
    attrs: {
      field: "id",
      title: "ID",
      width: "70"
    }
  }), _c("ux-table-column", {
    attrs: {
      field: "name",
      title: "角色名称",
      "min-width": "180",
      resizable: ""
    }
  }), _c("ux-table-column", {
    attrs: {
      field: "createTime",
      title: "创建时间",
      "min-width": "180",
      resizable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.createTime))])];
      }
    }])
  }), _c("ux-table-column", {
    attrs: {
      field: "updateTime",
      title: "更新时间",
      "min-width": "180",
      resizable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.updateTime))])];
      }
    }])
  }), _c("ux-table-column", {
    attrs: {
      field: "isEdit",
      title: "可否修改",
      "min-width": "180",
      resizable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [scope.row.isEdit ? _c("el-tag", [_vm._v("是")]) : _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v("否")])], 1)];
      }
    }])
  }), _c("ux-table-column", {
    attrs: {
      title: "操作",
      width: "350",
      resizable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.updataRole(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c("el-popover", {
          staticClass: "del-popover",
          attrs: {
            placement: "bottom",
            width: "240",
            trigger: "click"
          },
          model: {
            value: scope.row.visible,
            callback: function callback($$v) {
              _vm.$set(scope.row, "visible", $$v);
            },
            expression: "scope.row.visible"
          }
        }, [_c("p", [_vm._v("你正在执行删除操作，确定删除吗？")]), _c("div", {
          staticStyle: {
            "text-align": "right",
            margin: "0"
          }
        }, [_c("el-button", {
          attrs: {
            size: "mini",
            type: "text"
          },
          on: {
            click: function click($event) {
              scope.row.visible = false;
            }
          }
        }, [_vm._v("取消")]), _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            loading: scope.row.delLoading
          },
          on: {
            click: function click($event) {
              return _vm.del(scope.row);
            }
          }
        }, [_vm._v("确定")])], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            type: "danger",
            size: "mini",
            disabled: scope.row.id === 1
          },
          slot: "reference"
        }, [_vm._v("删除")])], 1)], 1)];
      }
    }])
  })], 1)], 1), _c("menuPermission", {
    ref: "menuPermission",
    on: {
      success: _vm.getRoleList
    }
  }), _c("rolePermission", {
    ref: "rolePermission"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };