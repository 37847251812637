import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "角色菜单权限",
      visible: _vm.roleRouteVisible,
      width: "60%",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.roleRouteVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "add-role-form-warp"
  }, [_c("el-form", {
    ref: "addRoleRouteForm",
    staticClass: "add-role-form",
    attrs: {
      model: _vm.addRoleRouteForm,
      "label-width": "80px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "角色名称"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.addRoleRouteForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.addRoleRouteForm, "name", $$v);
      },
      expression: "addRoleRouteForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "可否修改"
    }
  }, [_c("div", [_c("el-select", {
    model: {
      value: _vm.addRoleRouteForm.isEdit,
      callback: function callback($$v) {
        _vm.$set(_vm.addRoleRouteForm, "isEdit", $$v);
      },
      expression: "addRoleRouteForm.isEdit"
    }
  }, [_c("el-option", {
    attrs: {
      value: true,
      label: "是"
    }
  }), _c("el-option", {
    attrs: {
      value: false,
      label: "否"
    }
  })], 1), _c("div", {
    staticStyle: {
      color: "rgb(255, 9, 9)"
    }
  }, [_vm._v("注:是否有编辑角色的权限")])], 1)]), _c("el-form-item", {
    attrs: {
      label: "菜单权限"
    }
  }, [_c("div", {
    staticStyle: {
      color: "rgb(255, 9, 9)"
    }
  }, [_vm._v("注:红色菜单涉及系统权限谨慎分配")]), _c("el-tree", {
    ref: "tree",
    attrs: {
      data: _vm.menuData,
      "show-checkbox": "",
      "default-expand-all": "",
      "default-checked-keys": _vm.defaultCheckedKeys,
      "node-key": "id",
      "highlight-current": "",
      props: {
        children: "children",
        label: "name"
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var data = _ref.data;
        return _c("div", {
          staticClass: "slot-tree-node"
        }, [_c("div", {
          staticClass: "flex"
        }, [data.id == "25" || data.id == "26" || data.id == "27" || data.id == "28" || data.id == "4" || data.id == "22" || data.id == "47" || data.id == "48" || data.id == "71" || data.id == "72" ? _c("div", {
          staticClass: "nodeclass"
        }, [_vm._v(_vm._s(data.name))]) : _c("div", [_vm._v(_vm._s(data.name))]), data.type == 1 ? _c("div", {
          staticStyle: {
            "margin-left": "20px",
            color: "rgb(138, 198, 233)"
          }
        }, [_vm._v("菜单")]) : _vm._e(), data.type == 0 ? _c("div", {
          staticStyle: {
            "margin-left": "20px",
            color: "rgb(235, 122, 122)"
          }
        }, [_vm._v("权限")]) : _vm._e()])]);
      }
    }])
  })], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.roleRouteVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };