import request from '../request/index';
import { jsonToFormdata } from '../request/tools';

// 获取字典data列表
export var getDictDataList = function getDictDataList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/system/dict/data/list',
    method: 'get',
    data: data
  });
};

// 获取字典datainfo
export var getDictDataInfo = function getDictDataInfo(id) {
  // console.log(data)
  return request({
    url: '/system/dict/data/' + id,
    method: 'get'
  });
};

// 修改字典datainfo
export var editDictDataInfo = function editDictDataInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/system/dict/data',
    method: 'put',
    data: data
  });
};

// 添加字典data
export var addDictData = function addDictData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/system/dict/data',
    method: 'post',
    data: data
  });
};
// s删除字典 data
export var delDictData = function delDictData(id) {
  // console.log(data)
  return request({
    url: '/system/dict/data/' + id,
    method: 'delete'
  });
};