import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", [_c("el-form", {
    attrs: {
      inline: true
    }
  }, [_c("el-button", {
    attrs: {
      type: "warning",
      icon: "el-icon-refresh"
    },
    on: {
      click: function click($event) {
        return _vm.reload();
      }
    }
  }, [_vm._v("更新")])], 1)], 1), _c("div", {
    staticClass: "tabs"
  }, _vm._l(_vm.tabs, function (item) {
    return _c("div", {
      key: item.id,
      class: {
        active: _vm.activeName === item.label
      }
    }, [_c("span", {
      on: {
        click: function click($event) {
          return _vm.tabChange(item.label);
        }
      }
    }, [_vm._v(_vm._s(item.label))])]);
  }), 0), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.datainfo,
      border: "",
      stripe: "",
      height: _vm.$clientHeight
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center",
      label: "序号",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "shopName",
      align: "center",
      label: "商家名称"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "title",
      align: "center",
      label: "视频标题"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "totalNum",
      align: "center",
      label: "总可观看次数"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "oncePrice",
      align: "center",
      label: "单价"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "videoUrl",
      align: "center",
      label: "总价"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s((scope.row.oncePrice * scope.row.totalNum).toFixed(2)) + " ")];
      }
    }])
  }), _vm.activeName == "审核通过" ? _c("el-table-column", {
    attrs: {
      prop: "watchNum",
      align: "center",
      label: "已观看次数"
    }
  }) : _vm._e(), _vm.activeName == "审核通过" ? _c("el-table-column", {
    attrs: {
      prop: "applyTime",
      align: "center",
      label: "审核时间"
    }
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      align: "center",
      label: "发起申请时间"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "videoUrl",
      align: "center",
      label: "视频地址"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "warning",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.videoView(scope.row);
            }
          }
        }, [_vm._v("查看")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "state",
      align: "center",
      label: "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.state == 0 ? "申请中" : scope.row.state == 1 ? "申请失败" : "申请成功"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作",
      width: "380"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.activeName == "审核通过" ? _c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.editclick(scope.row);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), _vm.activeName == "审核通过" ? _c("el-button", {
          attrs: {
            type: "info"
          },
          on: {
            click: function click($event) {
              return _vm.seeclick(scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e(), _vm.activeName == "审核通过" ? _c("el-button", {
          attrs: {
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.virtualWatch(scope.row);
            }
          }
        }, [_vm._v("虚拟观看次数")]) : _vm._e(), _vm.activeName == "待审核" ? _c("el-button", {
          attrs: {
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.reviewclick(scope.row);
            }
          }
        }, [_vm._v("审核")]) : _vm._e()];
      }
    }])
  })], 1), _c("div", {
    staticClass: "mgt20"
  }, [_vm.page.total > 10 ? _c("page", {
    attrs: {
      page: _vm.page
    },
    on: {
      pageChange: _vm.pageChange
    }
  }) : _vm._e()], 1)], 1), _c("el-dialog", {
    attrs: {
      width: "500px",
      title: "视频",
      visible: _vm.videovisible,
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.videovisible = $event;
      }
    }
  }, [_vm.videovisible ? _c("el-form", {
    attrs: {
      model: _vm.selecthandlist,
      "label-width": "55px"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "clientName"
    }
  }, [_c("video", {
    ref: "vidoe",
    attrs: {
      width: "350",
      height: "300",
      controls: ""
    }
  }, [_c("source", {
    attrs: {
      src: _vm.selecthandlist.videoUrl,
      type: "video/mp4"
    }
  })])])], 1) : _vm._e()], 1), _c("div", [_c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.handleCloseEdit,
      visible: _vm.editDialogVisible,
      title: _vm.dialogTitle,
      width: "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "editFormData",
    attrs: {
      model: _vm.editFormData,
      rules: _vm.rules,
      "label-width": "110px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "可观看次数",
      prop: "totalNum",
      type: "number"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-input", {
    model: {
      value: _vm.editFormData.totalNum,
      callback: function callback($$v) {
        _vm.$set(_vm.editFormData, "totalNum", $$v);
      },
      expression: "editFormData.totalNum"
    }
  })], 1)], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.editSubmitForm
    }
  }, [_vm._v("确定")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      type: "info"
    },
    on: {
      click: _vm.handleCloseEdit
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.virtualWatchDialogVisible,
      title: "虚拟观看次数"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.virtualWatchDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "virtualWatch",
    attrs: {
      model: _vm.virtualWatchData,
      "label-width": "110px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "已虚拟观看次数",
      prop: "totalNum",
      type: "number"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.virtualWatchData.num,
      callback: function callback($$v) {
        _vm.$set(_vm.virtualWatchData, "num", $$v);
      },
      expression: "virtualWatchData.num"
    }
  })], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.virtualWatchSubmitForm
    }
  }, [_vm._v("确定")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      type: "info"
    },
    on: {
      click: function click($event) {
        _vm.virtualWatchDialogVisible = false;
      }
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1), _c("div", [_c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.handleCloseSee,
      visible: _vm.seeDialogVisible,
      title: _vm.dialogTitle,
      width: "550px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.seeDialogVisible = $event;
      }
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.seeFormData,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center",
      label: "序号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userName",
      align: "center",
      label: "观看用户名称"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userPhone",
      align: "center",
      label: "手机号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      align: "center",
      label: "观看时间"
    }
  })], 1)], 1)], 1), _c("div", [_c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.handleCloseReview,
      visible: _vm.reviewDialogVisible,
      title: _vm.dialogTitle,
      width: "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.reviewDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "reviewFormData",
    attrs: {
      model: _vm.reviewFormData,
      rules: _vm.rules,
      size: "small",
      "label-width": "130px"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      label: "审核结果",
      prop: "value"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.changereview
    },
    model: {
      value: _vm.reviewFormData.value,
      callback: function callback($$v) {
        _vm.$set(_vm.reviewFormData, "value", $$v);
      },
      expression: "reviewFormData.value"
    }
  }, [_c("el-option", {
    attrs: {
      label: "通过",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "不通过",
      value: false
    }
  })], 1)], 1), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    attrs: {
      label: "可观看次数",
      prop: "Num",
      type: "number"
    },
    on: {
      input: _vm.inputchange
    }
  }, [_c("el-input", {
    model: {
      value: _vm.reviewFormData.Num,
      callback: function callback($$v) {
        _vm.$set(_vm.reviewFormData, "Num", $$v);
      },
      expression: "reviewFormData.Num"
    }
  })], 1), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visibletextarea,
      expression: "visibletextarea"
    }],
    attrs: {
      label: "审批意见",
      prop: "textarea"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "400px"
    },
    attrs: {
      type: "textarea",
      placeholder: "请输入审核意见......",
      maxlength: 100,
      rows: 5
    },
    on: {
      input: _vm.inputchange
    },
    model: {
      value: _vm.reviewFormData.textarea,
      callback: function callback($$v) {
        _vm.$set(_vm.reviewFormData, "textarea", $$v);
      },
      expression: "reviewFormData.textarea"
    }
  })], 1)], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.reviewSubmitForm
    }
  }, [_vm._v("确定")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      size: "mini",
      type: "info"
    },
    on: {
      click: _vm.handleCloseReview
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };