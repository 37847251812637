import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dropdown", {
    staticClass: "d2-mr",
    attrs: {
      size: "small"
    }
  }, [_c("span", {
    staticClass: "btn-text"
  }, [_vm._v(_vm._s(_vm.info.name ? "\u4F60\u597D ".concat(_vm.info.name) : "未登录"))]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    nativeOn: {
      click: function click($event) {
        return _vm.logOff.apply(null, arguments);
      }
    }
  }, [_c("d2-icon", {
    staticClass: "d2-mr-5",
    attrs: {
      name: "power-off"
    }
  }), _vm._v(" 注销 ")], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };