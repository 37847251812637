import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
//添加数据到本地缓存
export var setLocalStorage = function setLocalStorage(name, value) {
  if (!name) return;
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  window.localStorage.setItem("d2admin-".concat(process.env.VUE_APP_VERSION, "-").concat(name), value);
};

//获取
export var getLocalStorage = function getLocalStorage(name) {
  if (!name) return;
  return window.localStorage.getItem("d2admin-".concat(process.env.VUE_APP_VERSION, "-").concat(name));
};
//移除
export var removeLocalStorage = function removeLocalStorage(name) {
  if (!name) return;
  window.localStorage.removeItem("d2admin-".concat(process.env.VUE_APP_VERSION, "-").concat(name));
};