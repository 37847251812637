var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mannage"
  }, [_c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.handleClose,
      visible: _vm.visible,
      title: _vm.title,
      width: "700px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.datalist,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center",
      label: "序号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "title",
      align: "center",
      label: "文章标题"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userName",
      align: "center",
      label: "用户名称"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userPhoto",
      align: "center",
      label: "用户头像"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.userPhoto ? _c("el-image", {
          staticStyle: {
            width: "90px",
            height: "50px"
          },
          attrs: {
            "z-index": 4000,
            src: scope.row.userPhoto,
            "preview-src-list": [scope.row.userPhoto]
          }
        }) : _c("div", [_vm._v("暂无图片")])];
      }
    }])
  }), _vm.str == 2 ? _c("el-table-column", {
    attrs: {
      prop: "niubiNum",
      align: "center",
      label: "牛币数量"
    }
  }) : _vm._e(), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      align: "center",
      label: "创建时间"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return scope.row.createTime ? [_vm._v(" " + _vm._s(scope.row.createTime.substring(0, 10)) + " ")] : undefined;
      }
    }], null, true)
  })], 1), _vm.page.total > 9 ? _c("page", {
    attrs: {
      page: _vm.page
    },
    on: {
      pageChange: _vm.pageChange
    }
  }) : _vm._e()], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };