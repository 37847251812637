import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  name: "DictTag",
  props: {
    options: {
      type: Array,
      default: null
    },
    value: [Number, String, Array],
    // 当未找到匹配的数据时，显示value
    showValue: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      unmatchArray: [] // 记录未匹配的项
    };
  },
  computed: {
    values: function values() {
      if (this.value !== null && typeof this.value !== "undefined") {
        return Array.isArray(this.value) ? this.value : [String(this.value)];
      } else {
        return [];
      }
    },
    unmatch: function unmatch() {
      var _this = this;
      this.unmatchArray = [];
      if (this.value !== null && typeof this.value !== "undefined") {
        // 传入值为非数组
        if (!Array.isArray(this.value)) {
          if (this.options.some(function (v) {
            return v.value == _this.value;
          })) return false;
          this.unmatchArray.push(this.value);
          return true;
        }
        // 传入值为Array
        this.value.forEach(function (item) {
          if (!_this.options.some(function (v) {
            return v.value == item;
          })) _this.unmatchArray.push(item);
        });
        return true;
      }
      // 没有value不显示
      return false;
    }
  },
  filters: {
    handleArray: function handleArray(array) {
      if (array.length === 0) return "";
      return array.reduce(function (pre, cur) {
        return pre + " " + cur;
      });
    }
  }
};