var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "主题",
      placement: "bottom"
    }
  }, [_c("el-button", {
    staticClass: "d2-ml-0 d2-mr btn-text can-hover",
    attrs: {
      type: "text"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = true;
      }
    }
  }, [_c("d2-icon", {
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      name: "diamond"
    }
  })], 1)], 1), _c("el-dialog", {
    attrs: {
      title: "主题",
      width: "600px",
      visible: _vm.dialogVisible,
      "append-to-body": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("d2-theme-list", {
    staticStyle: {
      "margin-top": "-25px"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };