import request from '../request/index';
export var addgovuser = function addgovuser() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/govUser/add/govUser',
    method: 'post',
    data: data
  });
};
export var govUserList = function govUserList(id) {
  // console.log(data)
  return request({
    url: "/govUser/userList/".concat(id),
    method: 'get'
  });
};
export var updategovPWD = function updategovPWD() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/govUser/change/password',
    method: 'post',
    data: data
  });
};
export var addgov = function addgov() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/govList/add',
    method: 'post',
    data: data
  });
};
export var govList = function govList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/govList/list',
    method: 'post',
    data: data
  });
};
export var delgov = function delgov(id) {
  // console.log(data)
  return request({
    url: "/govList/del/".concat(id),
    method: 'delete'
  });
};
export var getLocation = function getLocation(data) {
  // console.log(data)
  return request({
    url: "/user/getLocation",
    method: 'get',
    data: data
  });
};