var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.datalist,
      border: "",
      stripe: "",
      height: _vm.$clientHeight
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center",
      label: "序号",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "name",
      align: "center",
      label: "产品名称"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "message",
      align: "center",
      label: "产品描述"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "img",
      align: "center",
      label: "产品图片\t"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.img ? _c("el-image", {
          staticStyle: {
            width: "90px",
            height: "40px"
          },
          attrs: {
            "z-index": 4000,
            src: scope.row.img,
            "preview-src-list": [scope.row.img]
          }
        }) : _c("div", [_vm._v("暂无图片")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "产品价格",
      prop: "price"
    }
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "产品销量",
      prop: "sales"
    }
  }), _vm.datalist.weight != null ? _c("el-table-column", {
    attrs: {
      align: "center",
      label: "产品重量",
      prop: "weight"
    }
  }, [_vm._v(" " + _vm._s(_vm.scope.row.weight) + " ")]) : _vm._e(), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "是否上线",
      prop: "online"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.online ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("以上线")]) : _c("el-tag", [_vm._v("未上线")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作",
      flexd: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.online ? _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.changeStatus(1, scope.row);
            }
          }
        }, [_vm._v("冻结产品")]) : _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.changeStatus(2, scope.row);
            }
          }
        }, [_vm._v("解冻产品")])];
      }
    }])
  })], 1), _c("div", {
    staticClass: "mgt20"
  }, [_vm.page.total > 10 ? _c("page", {
    attrs: {
      page: _vm.page
    },
    on: {
      pageChange: _vm.pageChange
    }
  }) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };