import request from '../request/index';
import { jsonToFormdata } from '../request/tools';

// 商户视频列表
export var merchantVideoList = function merchantVideoList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  data = jsonToFormdata(data);
  return request({
    url: '/shop/video/list/to/manage',
    method: 'post',
    data: data
  });
};

// 平台视频列表
export var platformVideoList = function platformVideoList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data=jsonToFormdata(data)
  return request({
    url: '/shop/video/manage/list',
    method: 'get',
    data: data
  });
};
export var changevideostate = function changevideostate(id) {
  // data=jsonToFormdata(data)
  return request({
    url: "/shop/video/change/state/".concat(id),
    method: 'put'
  });
};

//修改审核通过的商家的可观看次数 formData 
export var editVideoNum = function editVideoNum() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  console.log(data);
  return request({
    url: '/shop/video/edit?videoId=' + data.videoId + '&totalNum=' + data.totalNum,
    method: 'put'
  });
};
//获取观看列表
export var getWacthList = function getWacthList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  data = jsonToFormdata(data);
  // console.log(data)
  return request({
    url: '/shop/video/watch/list',
    method: 'post',
    data: data
  });
};

//审核
export var approveVideo = function approveVideo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data=jsonToFormdata(data)
  console.log(data);
  return request({
    url: '/shop/video/approve?videoId=' + data.videoId + '&isSuccess=' + data.isSuccess + '&failMessage=' + data.failMessage + '&totalNum=' + data.totalNum,
    method: 'put'
  });
};

//添加平台视频
export var addPlatformVideo = function addPlatformVideo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/shop/video/admin/add',
    method: 'post',
    data: data
  });
};

//   //改变平台状态
// export const addPlatformVideo = (data = {}) => {
//   return request({
//     url: '/shop/video/admin/add',
//     method: 'post',
//     data
//   })
// }

export var setVirtualWatch = function setVirtualWatch() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  console.log(data);
  return request({
    url: '/shop/video/watch/setVirtualWatch',
    method: 'post',
    data: data
  });
};