import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import cityInfo from '@/libs/city.json';
export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    title: {
      default: "",
      type: String
    },
    list: {
      type: Object,
      default: function _default() {
        return [];
      }
    },
    select: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    list: function list(n, o) {
      this.formData = Object.assign({}, n);
      this.fileList = n.files;
      // this.$refs.editor.setContent(this.formData.content)
      if (this.title == "详情") {
        this.isHide = true;
      } else {
        this.isHide = false;
      }
    }
  },
  data: function data() {
    return {
      formData: {
        title: "",
        categoryId: "",
        // content:"",
        files: '',
        city: "",
        fileType: ""
      },
      fileList: [],
      rules: {
        title: [{
          require: true,
          message: "标题不能为空",
          trigger: "blur"
        }],
        content: [{
          require: true,
          message: "内容不能为空",
          trigger: "blur"
        }],
        fileType: [{
          require: true,
          message: "请选择类型",
          trigger: ["blur", "change"],
          type: 'number'
        }],
        categoryId: [{
          require: true,
          message: "分类不能为空",
          trigger: ["blur", "change"],
          type: 'number'
        }]
      },
      // title: "",
      // isVisible: false,
      province: "",
      //省
      city: "",
      //市
      country: "",
      //区
      arr: cityInfo.provinces.province,
      cityArr: [],
      countryArr: [],
      dialogImageUrl: "",
      dialogVisible: false,
      imageVideo: "",
      disabled: false,
      isHide: false
    };
  },
  methods: {
    getCity: function getCity() {
      for (var i = 0; i < this.arr.length; i++) {
        var obj = this.arr[i];
        if (this.province == obj.ssqname) {
          this.cityArr = obj.cities.city;
        }
      }
    },
    getCountry: function getCountry() {
      for (var index = 0; index < this.cityArr.length; index++) {
        var element = this.cityArr[index];
        if (this.city == element.ssqname) {
          this.countryArr = element.areas.area;
        }
      }
    },
    checkFileType: function checkFileType(status) {
      this.formData.fileType = status;
      this.fileList = [];
      if (this.title == '新增' && this.fileList != 0) {
        this.$refs.upload.uploadFiles = [];
      }
    },
    beforeupload: function beforeupload(file) {
      var _this = this;
      var url = "";
      if (file.type.indexOf("image") > -1) {
        url = this.$uploadImgurl + "/common/file/upload";
      } else if (file.type.indexOf("video") > -1) {
        url = this.$uploadImgurl + "/common/file/upload/video";
      }
      return new Promise(function (resolve, reject) {
        _this.imageVideo = url;
        // 在dom渲染完成之后就执行
        _this.$nextTick(function () {
          resolve();
        });
      });
    },
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      console.log(res, 669699);
      if (res.code == 200) {
        var list = {
          name: res.data[0].fileName,
          url: res.data[0].path,
          videoImg: res.data[0].videoImg != null ? res.data[0].videoImg : null
        };
        this.fileList.push(list);
      } else {
        this.$refs.upload.uploadFiles = [];
        this.$message.warning(res.message);
      }
    },
    fileChange: function fileChange() {
      if (this.fileList.length >= 9) {
        this.isHide = true;
      }
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    videoClose: function videoClose() {
      this.dialogVisible = false;
      this.$refs.vidoe.pause();
    },
    handleRemove: function handleRemove(file) {
      var _this2 = this;
      this.fileList.forEach(function (i, index) {
        if (file.name == i.name) {
          _this2.fileList.splice(index, 1);
        }
      });
    },
    handleRemove2: function handleRemove2(file) {
      var _this3 = this;
      this.fileList.forEach(function (i, index) {
        if (file.name == i.name) {
          _this3.fileList.splice(index, 1);
        }
      });
      this.$refs.upload.uploadFiles = [];
    },
    handleClose: function handleClose() {
      this.isHide = false;
      this.$emit("addDialogClose");
      this.resetFormData();
      this.fileList = [];
    },
    submitForm: function submitForm() {
      var valid = this.$refs["formData"].validate();
      if (valid) {
        var arrurl = [];
        this.fileList.forEach(function (i) {
          var li = {
            url: i.url,
            videoImg: i.videoImg
          };
          arrurl.push(li);
        });
        var arr = arrurl.join(",");
        if (arr.substr(0, 1) == ",") arr = arr.substr(1); //去掉第一个，号
        // console.log(arrurl, "==]=]]]");
        this.formData.files = arrurl;
        this.formData.city = this.province + this.city + this.county;
        if (this.$refs.upload.uploadFiles.length == 0) {
          this.$message.warning("请上传图片");
          return;
        }
      }
      // console.log(valid)

      // console.log("子组件内部的数据", this.formData)
      this.$emit("addSubmitForm", this.formData);
    },
    resetFormData: function resetFormData() {
      this.$refs["formData"].resetFields();
      this.formData = {
        title: "",
        categoryId: "",
        fileType: ""
      };
      this.province = "";
      this.city = "";
      this.country = "";
    }
  }
};