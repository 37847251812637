var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-pagination", {
    attrs: {
      "current-page": _vm.page.pageNum,
      "page-size": _vm.page.pageSize,
      layout: "total, prev, pager, next",
      total: _vm.page.total
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };