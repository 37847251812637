var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "Editor"
  }, [_c("Toolbar", {
    staticStyle: {
      "border-bottom": "1px solid #ccc"
    },
    attrs: {
      editor: _vm.editor,
      "default-config": _vm.toolbarConfig,
      mode: _vm.mode
    }
  }), _c("Editor", {
    staticStyle: {
      "overflow-y": "hidden"
    },
    style: {
      height: 400 + "px"
    },
    attrs: {
      "default-config": _vm.editorConfig,
      mode: _vm.mode
    },
    on: {
      onCreated: _vm.onCreated,
      onChange: _vm.onChange
    },
    model: {
      value: _vm.desc,
      callback: function callback($$v) {
        _vm.desc = $$v;
      },
      expression: "desc"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };