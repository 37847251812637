import request from '../request/index';
export var list = function list() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/redPack/list',
    method: 'get',
    data: data
  });
};
export var getByID = function getByID(ID) {
  return request({
    url: "/cwkj-red-paper/getById/".concat(ID),
    method: 'get'
  });
};
// export const edit = (data = {}) => {
//   return request({
//     url: '/cwkj-red-paper/red-paper',
//     method: 'post',
//     data
//   })
// }
export var add = function add() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log("inner",data)
  return request({
    url: '/redPack/addOrEdItInfo',
    method: 'post',
    data: data
  });
};
export var del = function del(id) {
  return request({
    url: '/redPack/del/' + id,
    method: 'delete'
  });
};

// export const redGet = (data = {}) => {
//   return request({
//     url: '/cwkj-red-get-record/red-get',
//     method: 'get',
//     data
//   })
// }