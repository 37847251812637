var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "pad20"
  }, [_c("div", [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-circle-plus-outline"
    },
    on: {
      click: _vm.handleAddRow
    }
  }, [_vm._v("新增")])], 1)], 1), _c("div", {
    staticClass: "tabs"
  }, _vm._l(_vm.tabs, function (item) {
    return _c("div", {
      key: item.id,
      class: {
        active: _vm.activeName === item.label
      }
    }, [_c("span", {
      on: {
        click: function click($event) {
          return _vm.tabChange(item.label);
        }
      }
    }, [_vm._v(_vm._s(item.label))])]);
  }), 0), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.formdata,
      stripe: "",
      height: _vm.$clientHeight
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center",
      width: "70",
      label: "序号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "nickName",
      align: "center",
      label: "姓名"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "city",
      align: "center",
      label: "城市"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "profilePhoto",
      align: "center",
      label: "头像"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.profilePhoto ? _c("el-image", {
          staticStyle: {
            width: "90px",
            height: "50px"
          },
          attrs: {
            "z-index": 4000,
            src: scope.row.profilePhoto,
            "preview-src-list": [scope.row.profilePhoto]
          }
        }) : _c("div", [_vm._v("暂无图片")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "gender",
      align: "center",
      label: "性别"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.gender == 1 ? "男" : scope.row.gender == 2 ? "女" : "未知"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "updateTime",
      align: "center",
      label: "创建时间"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return scope.row.updateTime ? [_vm._v(" " + _vm._s(scope.row.updateTime.substring(0, 10)) + " ")] : undefined;
      }
    }], null, true)
  }), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "300",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "success",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.edit(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.deleteis(scope.row.id);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _vm.page.total > 10 ? _c("page", {
    attrs: {
      page: _vm.page
    },
    on: {
      pageChange: _vm.pageChange
    }
  }) : _vm._e(), _c("div", [_c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.addclose,
      visible: _vm.addDialogVisible,
      title: _vm.dialogTitle,
      width: "450px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData,
      size: "small",
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "城市"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请填写"
    },
    model: {
      value: _vm.formData.city,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "city", $$v);
      },
      expression: "formData.city"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "性别"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      size: "mini",
      clearable: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "gender", $$v);
      },
      expression: "formData.gender"
    }
  }, [_c("el-option", {
    attrs: {
      label: "男",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "女",
      value: 2
    }
  }), _c("el-option", {
    attrs: {
      label: "未知",
      value: 3
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "昵称"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      placeholder: "请输入昵称"
    },
    model: {
      value: _vm.formData.nickName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "nickName", $$v);
      },
      expression: "formData.nickName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "状态"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "200px"
    },
    attrs: {
      size: "mini",
      clearable: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  }, [_c("el-option", {
    attrs: {
      label: "启用",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "禁用",
      value: 0
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "头像"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "avatar-uploader",
    attrs: {
      action: _vm.$uploadImgurl + "/common/file/upload",
      "show-file-list": false,
      "on-success": _vm.handleAvatarSuccessimagekey,
      "before-upload": _vm.beforeAvatarUpload
    }
  }, [_vm.formData.profilePhoto ? _c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.formData.profilePhoto,
      width: "120px"
    }
  }) : _c("i", {
    staticClass: "el-icon-plus avatar-uploader-icon"
  })])], 1)], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("保存")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      size: "mini",
      type: "info"
    },
    on: {
      click: _vm.addclose
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };