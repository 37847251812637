import { render, staticRenderFns } from "./dictDetail.vue?vue&type=template&id=92b86e3a"
import script from "./dictDetail.vue?vue&type=script&lang=js"
export * from "./dictDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\code\\mn-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('92b86e3a')) {
      api.createRecord('92b86e3a', component.options)
    } else {
      api.reload('92b86e3a', component.options)
    }
    module.hot.accept("./dictDetail.vue?vue&type=template&id=92b86e3a", function () {
      api.rerender('92b86e3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dict/dictList/dictDetail.vue"
export default component.exports