import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("header", {
    staticClass: "head"
  }, [_c("el-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("el-button", {
    attrs: {
      type: "success",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.$refs.grid.setAllTreeExpand(true);
      }
    }
  }, [_vm._v("全部展开")]), _c("el-button", {
    attrs: {
      type: "info",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.$refs.grid.clearTreeExpand();
      }
    }
  }, [_vm._v("全部关闭")]), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addMenu
    }
  }, [_vm._v("新增")])], 1)], 1), _c("main", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "main"
  }, [_c("ux-grid", {
    ref: "grid",
    attrs: {
      "tree-config": {
        children: "children",
        iconOpen: "el-icon-arrow-down",
        iconClose: "el-icon-arrow-right"
      },
      border: "",
      height: _vm.$clientHeight + 50,
      size: "mini",
      stripe: "",
      "row-id": "id",
      "checkbox-config": {
        highlight: true
      }
    }
  }, [_c("ux-table-column", {
    attrs: {
      field: "id",
      title: "ID",
      width: "70"
    }
  }), _c("ux-table-column", {
    attrs: {
      field: "name",
      title: "菜单名称",
      "min-width": "150",
      "tree-node": ""
    }
  }), _c("ux-table-column", {
    attrs: {
      title: "菜单url",
      "min-width": "190"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: scope.row.path,
            placement: "bottom"
          }
        }, [_c("div", {
          staticClass: "overflow"
        }, [_vm._v(_vm._s(scope.row.path))])])];
      }
    }])
  }), _c("ux-table-column", {
    attrs: {
      field: "sort",
      title: "菜单优先级",
      "min-width": "150"
    }
  }), _c("ux-table-column", {
    attrs: {
      field: "permission",
      title: "permission",
      "min-width": "150"
    }
  }), _c("ux-table-column", {
    attrs: {
      title: "操作",
      width: "280"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_c("el-button", {
          attrs: {
            type: "warning",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.updataMenu(scope.row);
            }
          }
        }, [_vm._v("修改")]), _c("el-popover", {
          staticClass: "del-popover",
          attrs: {
            placement: "bottom",
            width: "240",
            trigger: "click"
          },
          model: {
            value: scope.row.visible,
            callback: function callback($$v) {
              _vm.$set(scope.row, "visible", $$v);
            },
            expression: "scope.row.visible"
          }
        }, [_c("p", [_vm._v("你正在执行删除操作，确定删除吗？")]), _c("div", {
          staticStyle: {
            "text-align": "right",
            margin: "0"
          }
        }, [_c("el-button", {
          attrs: {
            size: "mini",
            type: "text"
          },
          on: {
            click: function click($event) {
              scope.row.visible = false;
            }
          }
        }, [_vm._v("取消")]), _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            loading: scope.row.delLoading
          },
          on: {
            click: function click($event) {
              return _vm.del(scope.row);
            }
          }
        }, [_vm._v("确定")])], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            type: "danger",
            size: "mini"
          },
          slot: "reference"
        }, [_vm._v("删除")])], 1), scope.row.pid === 0 ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.addMenu(scope.row);
            }
          }
        }, [_vm._v("添加子菜单")]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1), _c("article", [_c("el-dialog", {
    attrs: {
      title: _vm.addMenuFormType == "add" ? "新增菜单" : "编辑菜单",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "add-route-form-warp"
  }, [_c("el-form", {
    ref: "addMenuForm",
    staticClass: "add-route-form",
    attrs: {
      model: _vm.addMenuForm,
      "label-width": "80px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "菜单名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "菜单名称"
    },
    model: {
      value: _vm.addMenuForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.addMenuForm, "name", $$v);
      },
      expression: "addMenuForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "上级"
    }
  }, [_c("el-cascader", {
    staticClass: "w-100",
    attrs: {
      options: _vm.menuData,
      props: {
        checkStrictly: true,
        label: "name",
        value: "id",
        emitPath: false
      },
      clearable: ""
    },
    model: {
      value: _vm.addMenuForm.pid,
      callback: function callback($$v) {
        _vm.$set(_vm.addMenuForm, "pid", $$v);
      },
      expression: "addMenuForm.pid"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Path"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "http或/开头"
    },
    model: {
      value: _vm.addMenuForm.path,
      callback: function callback($$v) {
        _vm.$set(_vm.addMenuForm, "path", $$v);
      },
      expression: "addMenuForm.path"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "排序值"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      placeholder: "值越大越靠前"
    },
    model: {
      value: _vm.addMenuForm.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.addMenuForm, "sort", $$v);
      },
      expression: "addMenuForm.sort"
    }
  })], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      loading: _vm.addMenuLoading,
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };