import request from '../request/index';
import request2 from '../request/requestTwo';

// 获取字典data列表
export var withdrawList = function withdrawList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/user/wallet/withdraw/list',
    method: 'get',
    data: data,
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  });
};
export var withdrawReal = function withdrawReal() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: 'user/wallet/withdraw/real',
    method: 'post',
    data: data
    // ,
    // headers: {
    //     'content-type': 'application/x-www-form-urlencoded'
    // }
  });
};
export var withdrawWithdrawReal = function withdrawWithdrawReal() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: 'user/wallet/withdraw/refuse',
    method: 'post',
    data: data
  });
};
export var withdrawWechatExport = function withdrawWechatExport() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request2({
    url: 'user/wallet/withdraw/wechat/export',
    method: 'post',
    data: data,
    noIntercept: true
  });
};