var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-circle-plus-outline"
    },
    on: {
      click: _vm.handleAddRow
    }
  }, [_vm._v("新增")])], 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.datainfo,
      border: true
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "index",
      align: "center",
      label: "序号",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "message",
      align: "center",
      label: "公告内容",
      "min-width": "180"
    }
  })], 1)], 1), _c("div", [_c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.addclose,
      visible: _vm.addDialogVisible,
      title: _vm.dialogTitle,
      width: "450px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData,
      size: "small",
      "label-width": "80px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "公告内容",
      prop: "message"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "320px"
    },
    attrs: {
      placeholder: "请填写"
    },
    model: {
      value: _vm.formData.message,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "message", $$v);
      },
      expression: "formData.message"
    }
  })], 1)], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("保存")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      size: "mini",
      type: "info"
    },
    on: {
      click: _vm.addclose
    }
  }, [_vm._v("取 消")])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };