import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.seal.js";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { getLocalStorage } from '@/libs/util.localStorage';
export default {
  name: 'editor',
  components: {
    Editor: Editor,
    Toolbar: Toolbar
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  watch: {
    value: {
      handler: function handler(desc) {
        this.desc = desc;
      },
      immediate: true
    }
  },
  beforeDestroy: function beforeDestroy() {
    var editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  data: function data() {
    return {
      desc: '',
      editor: null,
      toolbarConfig: {},
      editorConfig: this.setEditorConfig(),
      mode: 'default' // or 'simple'
    };
  },
  methods: {
    onCreated: function onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    setEditorConfig: function setEditorConfig() {
      var _this = this;
      var config = {
        MENU_CONF: {
          uploadImage: {}
        }
      };
      config.MENU_CONF.uploadImage = {
        server: "".concat(process.env.VUE_APP_BASE_API, "/common/file/upload"),
        fieldName: 'file',
        maxFileSize: 100 * 1024 * 1024,
        headers: {
          Auth: getLocalStorage('token')
        },
        customInsert: function customInsert(res, insertFn) {
          insertFn(res.data[0].path, '图片', res.data[0].path);
        },
        onFailed: function onFailed(file, res) {
          console.log("".concat(file.name, " \u4E0A\u4F20\u5931\u8D25"), res);
          _this.$message.error(res.message);
        }
      };
      // 视频
      config.MENU_CONF.uploadVideo = {
        server: "".concat(process.env.VUE_APP_BASE_API, "/common/file/upload"),
        fieldName: 'file',
        maxFileSize: 500 * 1024 * 1024,
        headers: {
          Auth: getLocalStorage('token')
        },
        customInsert: function customInsert(res, insertFn) {
          console.log(res.data, '视频');
          insertFn(res.data[0].path);
        },
        onFailed: function onFailed(file, res) {
          console.log("".concat(file.name, " \u4E0A\u4F20\u5931\u8D25"), res);
          _this.$message.error(res.message);
        }
      };
      return config;
    },
    onChange: function onChange(e) {
      var isEmpty = this.editor.isEmpty();
      var html = e.getHtml();
      this.$emit('input', isEmpty ? '' : html);
    }
  }
};