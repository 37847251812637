import request from '../request/index';

// 获取字典data列表
export var getHouseFormuList = function getHouseFormuList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumHouseInfo/applyList',
    method: 'post',
    data: data
  });
};

// 审核文章结果
export var editHouseFormu = function editHouseFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumHouseInfo/apply',
    method: 'post',
    data: data
  });
};

// 上架文章
export var uploadHouseFormu = function uploadHouseFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumHouseInfo/changeOnline',
    method: 'post',
    data: data
  });
};