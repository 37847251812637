import request from '../request/index';
import { jsonToFormdata } from '../request/tools';

// 获取字典type列表
export var getDictList = function getDictList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/system/dict/type/list',
    method: 'get',
    data: data
  });
};

// 获取字典详情 
export var getDictTypeInfo = function getDictTypeInfo(id) {
  // console.log(data)
  return request({
    url: '/system/dict/type/' + id,
    method: 'get'
  });
};

// 修改字典type 
export var editDict = function editDict() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/system/dict/type',
    method: 'put',
    data: data
  });
};
// s删除字典 
export var delDictType = function delDictType(id) {
  // console.log(data)
  return request({
    url: '/system/dict/type/' + id,
    method: 'delete'
  });
};

// 添加字典 
export var addDictType = function addDictType() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/system/dict/type',
    method: 'post',
    data: data
  });
};

// 获取字典选择框列表
export var optionselect = function optionselect() {
  return request({
    url: '/system/dict/type/optionselect',
    method: 'get'
  });
};

//刷新缓存
export var refreshCache = function refreshCache() {
  return request({
    url: '/system/dict/type/refreshCache',
    method: 'delete'
  });
};