var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", {
    staticClass: "header-warp"
  }, [_vm.status === 1 ? _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("开奖颜色")])]), _c("div", {
    staticClass: "flex"
  }, _vm._l(_vm.lotteryColor, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "font-turntable",
      class: {
        "lottery-text": _vm.form.lotteryColor === item.value
      },
      staticStyle: {
        width: "100px",
        height: "100px",
        "margin-right": "10px"
      },
      on: {
        click: function click($event) {
          return _vm.setLotteryColor(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]);
  }), 0)]) : _vm._e(), _vm.status === 1 ? _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("开奖字母")])]), _c("div", {
    staticClass: "flex"
  }, _vm._l(_vm.lotteryEn, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "font-turntable",
      class: {
        "lottery-en": _vm.form.lotteryEn === item.text
      },
      staticStyle: {
        width: "100px",
        height: "100px",
        "margin-right": "10px"
      },
      on: {
        click: function click($event) {
          return _vm.setLotteryEn(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]);
  }), 0)]) : _vm._e(), _vm.status === 1 ? _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("开奖文字")])]), _c("div", {
    staticClass: "flex"
  }, _vm._l(_vm.lotteryText, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "font-turntable2",
      class: {
        "lottery-text": _vm.form.lotteryText === item.text
      },
      staticStyle: {
        width: "50px",
        height: "50px",
        "margin-right": "10px"
      },
      on: {
        click: function click($event) {
          return _vm.setLotteryText(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])]);
  }), 0)]) : _vm._e(), _vm.status === 1 ? _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("开奖号码")])]), _c("div", {
    staticClass: "flex"
  }, [_c("el-input-number", {
    attrs: {
      min: 0,
      max: 9,
      label: "第一位号码"
    },
    model: {
      value: _vm.number.num1,
      callback: function callback($$v) {
        _vm.$set(_vm.number, "num1", $$v);
      },
      expression: "number.num1"
    }
  }), _c("el-input-number", {
    attrs: {
      min: 0,
      max: 9,
      label: "第二位号码"
    },
    model: {
      value: _vm.number.num2,
      callback: function callback($$v) {
        _vm.$set(_vm.number, "num2", $$v);
      },
      expression: "number.num2"
    }
  }), _c("el-input-number", {
    attrs: {
      min: 0,
      max: 9,
      label: "第三位号码"
    },
    model: {
      value: _vm.number.num3,
      callback: function callback($$v) {
        _vm.$set(_vm.number, "num3", $$v);
      },
      expression: "number.num3"
    }
  }), _c("el-input-number", {
    attrs: {
      min: 0,
      max: 9,
      label: "第四位号码"
    },
    model: {
      value: _vm.number.num4,
      callback: function callback($$v) {
        _vm.$set(_vm.number, "num4", $$v);
      },
      expression: "number.num4"
    }
  }), _c("el-input-number", {
    attrs: {
      min: 0,
      max: 9,
      label: "第五位号码"
    },
    model: {
      value: _vm.number.num5,
      callback: function callback($$v) {
        _vm.$set(_vm.number, "num5", $$v);
      },
      expression: "number.num5"
    }
  })], 1)]) : _vm._e()], 1), _c("div", {
    staticClass: "open-jiang"
  }, [_c("el-button", {
    staticClass: "open-jiang-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.queryLottery
    }
  }, [_vm._v("查询奖券")]), _vm.status === 1 ? _c("el-button", {
    staticClass: "open-jiang-btn",
    attrs: {
      type: "danger"
    },
    on: {
      click: _vm.openLottery
    }
  }, [_vm._v("开始开奖")]) : _vm._e(), _c("el-button", {
    staticClass: "open-jiang-btn",
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.winLottery
    }
  }, [_vm._v("中奖列表")])], 1), _c("div", {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" 查询到的奖券共(" + _vm._s(_vm.tableData.length || 0) + "张) ")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      border: true,
      "max-height": "650px"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "id",
      align: "center",
      label: "ID",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryEn",
      align: "center",
      label: "字母",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryText",
      align: "center",
      label: "文字",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryNumber",
      align: "center",
      label: "号码",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm._f("filterNumber")(scope.row.lotteryNumber)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryColor",
      align: "center",
      label: "颜色",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm._f("filterColor")(scope.row.lotteryColor)) + " ")];
      }
    }])
  })], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.statusVisible,
      "before-close": function beforeClose() {
        return _vm.statusVisible = false;
      },
      title: "开奖"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.statusVisible = $event;
      }
    }
  }, [_c("div", [_c("el-form", [_c("el-form-item", {
    attrs: {
      label: "等级"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.awardForm.awardLevel,
      callback: function callback($$v) {
        _vm.$set(_vm.awardForm, "awardLevel", $$v);
      },
      expression: "awardForm.awardLevel"
    }
  }, _vm._l(_vm.awardLevel, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "颜色"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.awardForm.lotteryColor,
      callback: function callback($$v) {
        _vm.$set(_vm.awardForm, "lotteryColor", $$v);
      },
      expression: "awardForm.lotteryColor"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "字母"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.awardForm.lotteryEn,
      callback: function callback($$v) {
        _vm.$set(_vm.awardForm, "lotteryEn", $$v);
      },
      expression: "awardForm.lotteryEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "文字"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.awardForm.lotteryText,
      callback: function callback($$v) {
        _vm.$set(_vm.awardForm, "lotteryText", $$v);
      },
      expression: "awardForm.lotteryText"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "号码"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.awardForm.lotteryNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.awardForm, "lotteryNumber", $$v);
      },
      expression: "awardForm.lotteryNumber"
    }
  })], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click() {
        return _vm.statusVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)]), _c("el-dialog", {
    attrs: {
      visible: _vm.winVisible,
      "before-close": function beforeClose() {
        return _vm.winVisible = false;
      },
      title: "中奖列表",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.winVisible = $event;
      }
    }
  }, [_c("div", [_c("el-dialog", {
    attrs: {
      width: "90%",
      title: "中奖名单",
      visible: _vm.winUserVisible,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.winUserVisible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.winUserList,
      border: true,
      "max-height": "350px"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "id",
      align: "center",
      label: "id",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userId",
      align: "center",
      label: "用户ID",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "nickName",
      align: "center",
      label: "用户名称",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userPhone",
      align: "center",
      label: "用户手机号",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryEn",
      align: "center",
      label: "字母",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryText",
      align: "center",
      label: "文字",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryNumber",
      align: "center",
      label: "号码",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm._f("filterNumber")(scope.row.lotteryNumber)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryColor",
      align: "center",
      label: "颜色",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm._f("filterColor")(scope.row.lotteryColor)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "nickName",
      align: "center",
      label: "奖券来源",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(" " + _vm._s(_vm._f("filterLotterySource")(scope.row.lotterySource)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "nickName",
      align: "center",
      label: "操作",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.lotteryStatus == 2 && scope.row.awardLevel !== "四等奖" ? _c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.hexiao(scope.row);
            }
          }
        }, [_vm._v("核销")]) : _vm._e(), scope.row.lotteryStatus == 2 && scope.row.awardLevel == "四等奖" ? _c("div", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v("未申请")]) : _vm._e(), scope.row.lotteryStatus == 6 && scope.row.awardLevel == "四等奖" ? _c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.showPayNiuBiVisible(scope.row);
            }
          }
        }, [_vm._v("同意发放牛币")]) : _vm._e(), scope.row.lotteryStatus == 4 ? _c("div", [_vm._v("已核销")]) : _vm._e()];
      }
    }])
  })], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.openTurnTableHistoryList,
      border: true,
      "max-height": "350px"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "awardLevel",
      align: "center",
      label: "等级",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryColor",
      align: "center",
      label: "颜色",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm._f("filterColor")(scope.row.lotteryColor)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryEn",
      align: "center",
      label: "字母",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryText",
      align: "center",
      label: "文字",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "lotteryNumber",
      align: "center",
      label: "号码",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm._f("filterNumber")(scope.row.lotteryNumber)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "aimCount",
      align: "center",
      label: "中奖人数",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.aimCount) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.queryWinList(scope.row);
            }
          }
        }, [_vm._v("查看名单")])];
      }
    }])
  })], 1), _c("el-dialog", {
    attrs: {
      width: "60%",
      title: "发放牛币",
      visible: _vm.payNiuBiVisible,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.payNiuBiVisible = $event;
      }
    }
  }, [_c("div", {
    staticStyle: {
      height: "300px"
    }
  }, [_vm._v(" 注意：牛币直接发放到用户账户，已核销的用户自动跳过发放，发放成功后奖券状态变为已核销 "), _c("div", {
    staticStyle: {
      "margin-top": "80px",
      "margin-bottom": "70px"
    }
  }, [_vm._v(" 发放的牛币数量 "), _c("el-input", {
    attrs: {
      type: "number",
      placeholder: "请输入牛币数量"
    },
    model: {
      value: _vm.niubiRow.addNiuBi,
      callback: function callback($$v) {
        _vm.$set(_vm.niubiRow, "addNiuBi", $$v);
      },
      expression: "niubiRow.addNiuBi"
    }
  })], 1), _c("span", {
    staticClass: "dialog-footer-nb",
    staticStyle: {
      "margin-top": "18px"
    },
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click() {
        return _vm.payNiuBiVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.payNiuBiSubmit();
      }
    }
  }, [_vm._v("确 定")])], 1)])])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };