import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "pad20"
  }, [_c("div", [_c("el-form", {
    attrs: {
      inline: true
    }
  }, [_c("el-form-item", [_c("el-button", {
    attrs: {
      icon: "el-icon-search",
      type: "primary"
    },
    on: {
      click: _vm.handleCheck
    }
  }, [_vm._v("刷新")]), _c("el-button", {
    attrs: {
      type: "danger",
      icon: "el-icon-circle-plus-outline"
    },
    on: {
      click: _vm.handleAddRow
    }
  }, [_vm._v("新增")])], 1)], 1)], 1), _c("div", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("已准备开放管理权限的区域")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.tableData,
      stripe: "",
      height: _vm.$clientHeight
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center",
      width: "70",
      label: "序号"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "province",
      align: "center",
      label: "省"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "city",
      align: "center",
      label: "市"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "area",
      align: "center",
      label: "区"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "creatTime",
      align: "center",
      label: "创建时间"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "updateTime",
      align: "center",
      label: "更新时间"
    }
  }), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "350",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.delGove(scope.row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      "before-close": _vm.addclose,
      visible: _vm.addDialogVisible,
      title: _vm.lodingTitle,
      width: "850px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addDialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "tableData",
    attrs: {
      model: _vm.formdata,
      size: "small",
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "地址搜索"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      remote: "",
      "value-key": "id",
      "reserve-keyword": "",
      placeholder: "输入区域",
      "remote-method": _vm.getLocation,
      loading: _vm.locationLoading
    },
    on: {
      change: _vm.locationChange
    },
    model: {
      value: _vm.formdata.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formdata, "value", $$v);
      },
      expression: "formdata.value"
    }
  }, _vm._l(_vm.optionsLocation, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.cityname + item.name,
        value: item
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "省"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: "",
      placeholder: "请填写"
    },
    model: {
      value: _vm.formdata.province,
      callback: function callback($$v) {
        _vm.$set(_vm.formdata, "province", $$v);
      },
      expression: "formdata.province"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "城市"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: "",
      placeholder: "请填写"
    },
    model: {
      value: _vm.formdata.city,
      callback: function callback($$v) {
        _vm.$set(_vm.formdata, "city", $$v);
      },
      expression: "formdata.city"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "区"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: "",
      placeholder: "请填写"
    },
    model: {
      value: _vm.formdata.area,
      callback: function callback($$v) {
        _vm.$set(_vm.formdata, "area", $$v);
      },
      expression: "formdata.area"
    }
  })], 1)], 1), _c("el-row", {
    staticStyle: {
      "margin-top": "17px"
    }
  }, [_c("el-button", {
    staticStyle: {
      float: "right",
      "margin-left": "6px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("保存")]), _c("el-button", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      size: "mini",
      type: "info"
    },
    on: {
      click: _vm.addclose
    }
  }, [_vm._v("取 消")])], 1)], 1), _vm.page.total > 10 ? _c("page", {
    attrs: {
      page: _vm.page
    },
    on: {
      pageChange: _vm.pageChange
    }
  }) : _vm._e()], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };