import request from '../request/index';
export var addGiftInfo = function addGiftInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/giftInfo/add',
    method: 'post',
    data: data
  });
};
export var deleteGiftInfo = function deleteGiftInfo() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  // console.log(data)
  return request({
    url: "/giftInfo/del/".concat(id),
    method: 'delete'
  });
};
export var putGiftInfo = function putGiftInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/giftInfo/edit",
    method: 'put',
    data: data
  });
};
export var giftInfoList = function giftInfoList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: "/giftInfo/list",
    method: 'post',
    data: data
  });
};