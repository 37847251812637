var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("div", {
    staticClass: "img-warp"
  }, [_c("img", {
    staticStyle: {
      width: "400px",
      height: "750px"
    },
    attrs: {
      src: require("@/static/img/homeOne.png"),
      fit: "fit"
    }
  }), _c("img", {
    staticStyle: {
      width: "400px",
      height: "750px"
    },
    attrs: {
      src: require("@/static/img/homeTwo.png"),
      fit: "fit"
    }
  }), _c("img", {
    staticStyle: {
      width: "400px",
      height: "750px"
    },
    attrs: {
      src: require("@/static/img/homeSwer.png"),
      fit: "fit"
    }
  })]), _c("div", {
    staticClass: "web-inspector-hide-shortcut"
  }, [_c("span", [_vm._v(" 版权所有：四川创未网络科技有限公司 ")]), _vm._v("备案号："), _c("a", {
    staticClass: "web-herf",
    attrs: {
      href: "https://beian.miit.gov.cn"
    }
  }, [_vm._v("蜀ICP备2021006254号-1")])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };