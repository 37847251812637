import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export default {
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    from.meta["__stamp-".concat(from.path)] = Date.now();
    next(function (instance) {
      return instance.$router.replace({
        path: from.fullPath,
        meta: from.meta
      });
    });
  },
  render: function render(h) {
    return h();
  }
};