import request from '../request/index';
import { jsonToFormdata } from '../request/tools';

// 获取字典data列表
export var getAppUpdateList = function getAppUpdateList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/app/version/list',
    method: 'get',
    data: data
  });
};

// 获取字典data列表
export var addUpdateInfo = function addUpdateInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/app/version/add',
    method: 'post',
    data: data
  });
};