import request from '../request/index';

// 获取字典data列表
export var getFriendFormuList = function getFriendFormuList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumFriendsInfo/applyList',
    method: 'post',
    data: data
  });
};

// 审核文章结果
export var editFriendFormu = function editFriendFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumFriendsInfo/apply',
    method: 'post',
    data: data
  });
};

// 上架文章
export var uploadFriendFormu = function uploadFriendFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumFriendsInfo/changeOnline',
    method: 'post',
    data: data
  });
};