var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("h2", [_vm._v("待审项目")]), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "merchantapply",
      label: "商家入驻",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.merchantapply === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/merchantManagement/merchantAudit"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.merchantapply))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "adverstisapply",
      label: "广告审核",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.adverstisapply === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/videoManagement/merchantVideoList"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.adverstisapply))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "riderapply",
      label: "骑手申请"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.riderapply === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/riderlist"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.riderapply))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "affairrole",
      label: "政务人员申请"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.affairrole === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/houseFormu/affairsApplyList"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.affairrole))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "affairapply",
      label: "本地政务"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.affairapply === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/houseFormu/affairsFormuApplyList"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.affairapply))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "localtalent",
      label: "本地人才"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.localtalent === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/houseFormu/workFormuApplyList"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.localtalent))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "localfriend",
      label: "本地交友"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.localfriend === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/houseFormu/friendFormuApplyList"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.localfriend))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "localhouse",
      label: "本地房产"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.localhouse === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/houseFormu/houseFormuApplyList"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.localhouse))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "localparadise",
      label: "学习乐园"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.localparadise === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/houseFormu/studyFormuApplyList"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.localparadise))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "locallife",
      label: "本地生活"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.locallife === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/houseFormu/lifeFormuApplyList"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.locallife))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "localcar",
      label: "本地汽车"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.localcar === 0 ? _c("div", [_vm._v("0")]) : _c("div", [_c("router-link", {
          staticClass: "link-type",
          attrs: {
            to: "/houseFormu/carFormuApplyList"
          }
        }, [_c("span", [_vm._v(" " + _vm._s(scope.row.localcar))])])], 1)];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };