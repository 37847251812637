import request from '../request/index';
import { jsonToFormdata } from '../request/tools';

// 商家入驻审核列表数据
export var managelist = function managelist() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  data = jsonToFormdata(data);
  return request({
    url: '/shop/list/to/manage',
    method: 'post',
    data: data
  });
};
export var shopApprove = function shopApprove() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "/shop/approve",
    method: 'post',
    data: data
  });
};
export var delShop = function delShop(id) {
  return request({
    url: '/shop/del/' + id,
    method: 'delete'
  });
};

// 商家列表数据 formData
export var merchantList = function merchantList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  data = jsonToFormdata(data);
  // console.log(data)
  return request({
    url: '/shop/list/to/manage',
    method: 'post',
    data: data
  });
};

// 商家列表数据 formData
// export const merchantList = (data = {}) => {
//   data = jsonToFormdata(data)
//   // console.log(data)
//   return request({
//     url: '/shop/list/to/manage/vip',
//     method: 'post',
//     data
//   })
// } 

// 修改商家比列 json
export var changePropotion = function changePropotion() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/shop/change/proportion',
    method: 'post',
    data: data
  });
};

// 商家订单数据 dataForm
export var getOrderList = function getOrderList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  data = jsonToFormdata(data);
  // console.log(data)
  return request({
    url: '/order/list/to/manage',
    method: 'post',
    data: data
  });
};

// 获取商家产品列表  
export var getMerchantGoodsList = function getMerchantGoodsList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data = jsonToFormdata(data)
  // console.log(data)
  return request({
    url: '/goods/sku/shop/list',
    method: 'get',
    data: data
  });
};

// 冻结商家产品列表  
export var editMerchantGoods1 = function editMerchantGoods1(id) {
  return request({
    url: '/goods/sku/downOnline/' + id,
    method: 'put'
  });
};

// 解冻商家产品列表  
export var editMerchantGoods2 = function editMerchantGoods2(id) {
  return request({
    url: '/goods/sku/upOnline/' + id,
    method: 'put'
  });
};

// 获取商家产品列表  
export var shopFrozen = function shopFrozen() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data = jsonToFormdata(data)
  // console.log(data)
  return request({
    url: '/shop/frozen',
    method: 'get',
    data: data
  });
};
export var onlineShop = function onlineShop() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  console.log(233);
  return request({
    url: '/shop/forceChange/online',
    method: 'get',
    data: data
  });
};
export var manageVipList = function manageVipList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(233)
  data = jsonToFormdata(data);
  return request({
    url: '/shop/list/to/manage/vip',
    method: 'post',
    data: data
  });
};
export var approveVip = function approveVip() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(233)
  return request({
    url: '/shop/approveVip',
    method: 'post',
    data: data
  });
};