var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", [_c("div", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      border: true
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "id",
      align: "center",
      label: "ID",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      align: "center",
      label: "红包领取时间",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userId",
      align: "center",
      label: "用户",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "businessId",
      align: "center",
      label: "商家",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "couponName",
      align: "center",
      label: "优惠券名称",
      "min-width": "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "title",
      align: "center",
      label: "红包类型",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [scope.row.redType === 0 ? _c("div", [_vm._v("现金")]) : _vm._e(), scope.row.redType === 1 ? _c("div", [_vm._v("优惠券")]) : _vm._e()])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "title",
      align: "center",
      label: "金额",
      "min-width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [scope.row.redType === 0 ? _c("div", [_vm._v(_vm._s(scope.row.redMoney))]) : _vm._e(), scope.row.redType === 1 ? _c("div", [_vm._v("满" + _vm._s(scope.row.couponDenominationReach) + "减" + _vm._s(scope.row.couponDenominationReduction))]) : _vm._e()])];
      }
    }])
  })], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };