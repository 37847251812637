import request from '../request/index';

// 获取字典data列表
export var getCarFormuList = function getCarFormuList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumCarInfo/applyList',
    method: 'post',
    data: data
  });
};

// 审核文章结果
export var editCarFormu = function editCarFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumCarInfo/apply',
    method: 'post',
    data: data
  });
};

// 上架文章
export var uploadCarFormu = function uploadCarFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumCarInfo/changeOnline',
    method: 'post',
    data: data
  });
};