import "core-js/modules/es.array.concat.js";
import layoutHeaderAside from '@/layout/header-aside';

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

/**
 * 在主框架内显示
 */
var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [
  // 首页
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('system/index')
  }, {
    path: 'gift',
    name: 'gift',
    meta: {
      auth: true,
      title: '打赏礼物列表'
    },
    component: _import('gift/index')
  }, {
    path: 'giveRewardStatics',
    name: 'giveRewardStatics',
    meta: {
      auth: true,
      title: '打赏礼物统计'
    },
    component: _import('dataStatistics/giveRewardStatics')
  }, {
    path: 'statisticsAudit',
    name: 'statisticsAudit',
    meta: {
      auth: true,
      title: '审核统计'
    },
    component: _import('dataStatistics/audit')
  }, {
    path: 'orderDataStatistics',
    name: 'orderDataStatistics',
    meta: {
      auth: true,
      title: '订单统计'
    },
    component: _import('dataStatistics/orderDataStatistics')
  }, {
    path: 'withdrawDepositwxchart',
    name: 'withdrawDepositwxchart',
    meta: {
      title: '微信申请列表',
      auth: true
    },
    component: _import('withdrawDeposit/wxchart')
  }, {
    path: 'withdrawDeposit',
    name: 'withdrawDeposit',
    meta: {
      title: '支付宝申请列表',
      auth: true
    },
    component: _import('withdrawDeposit/index')
  },
  // 红包页面
  {
    path: 'redPackage',
    name: 'redPackage',
    meta: {
      title: '红包配置',
      auth: true
    },
    component: _import('demo/page1')
  },
  //红包领取明细页面
  {
    path: 'redDetail',
    name: 'redDetail',
    meta: {
      title: '领取明细',
      auth: true
    },
    component: _import('demo/page2')
  }, {
    path: 'role',
    name: 'role',
    meta: {
      title: '角色管理',
      auth: true
    },
    component: _import('roleManagement/index')
  }, {
    path: 'menu',
    name: 'menu',
    meta: {
      title: '菜单管理',
      auth: true
    },
    component: _import('roleManagement/menu')
  }, {
    path: 'area',
    name: 'area',
    meta: {
      title: '区域管理',
      auth: true
    },
    component: _import('roleManagement/area')
  }, {
    path: 'administrator',
    name: 'administrator',
    meta: {
      title: '区域管理',
      auth: true
    },
    component: _import('roleManagement/administrator')
  }, {
    path: 'turntable',
    name: 'turntable',
    meta: {
      title: '转盘活动',
      auth: true
    },
    component: _import('activity/turntable')
  }, {
    path: 'IssueCoupons',
    name: 'IssueCoupons',
    meta: {
      title: '用户发放奖券',
      auth: true
    },
    component: _import('activity/IssueCoupons')
  }, {
    path: 'IssueCouponsStore',
    name: 'IssueCouponsStore',
    meta: {
      title: '商家发放奖券',
      auth: true
    },
    component: _import('activity/IssueCouponsStore')
  }, {
    path: 'auditShareCoupons',
    name: 'auditShareCoupons',
    meta: {
      title: '审核分享图',
      auth: true
    },
    component: _import('activity/auditShareCoupons')
  }, {
    path: 'queryTurntable',
    name: 'queryTurntable',
    meta: {
      title: '开奖查询',
      auth: true
    },
    component: _import('activity/queryTurntable')
  }, {
    path: 'merchantManagement',
    name: 'merchantManagement',
    meta: {
      title: '商家管理',
      auth: true
    },
    component: _import('merchantAudit/index'),
    children: [{
      path: 'divisionAccount',
      name: 'divisionAccount',
      meta: {
        title: '分账管理',
        auth: true
      },
      component: _import('merchantAudit/divisionAccount/index')
    }, {
      path: 'merchantVipAudit',
      name: 'merchantVipAudit',
      meta: {
        title: 'vip商家审核',
        auth: true
      },
      component: _import('merchantAudit/merchantVipAudit')
    }, {
      path: 'merchantAudit',
      name: 'merchantAudit',
      meta: {
        title: '商家入驻审核',
        auth: true
      },
      component: _import('merchantAudit/reviewList/index')
    }, {
      path: 'orderList',
      name: 'orderList',
      meta: {
        title: '订单列表',
        auth: true
      },
      component: _import('merchantAudit/orderList/index')
    }, {
      path: 'merchantClassification',
      name: 'merchantClassification',
      meta: {
        title: '商家分类',
        auth: true
      },
      component: _import('merchantAudit/merchantClassification/index')
    }, {
      path: 'merchantList',
      name: 'merchantList',
      meta: {
        title: '商家列表',
        auth: true
      },
      component: _import('merchantAudit/merchantList/index')
    }, {
      path: 'merchantGoodsList/:id',
      name: 'merchantGoodsList',
      meta: {
        title: '商家产品列表',
        auth: true
      },
      component: _import('merchantAudit/merchantList/merchantGoodsList')
    }]
  }, {
    path: 'userManagement',
    name: 'userManagement',
    meta: {
      title: '用户管理',
      auth: true
    },
    component: _import('userManagement/index'),
    children: [{
      path: 'userList',
      name: 'userList',
      meta: {
        title: '用户列表',
        auth: true
      },
      component: _import('userManagement/userList/index')
    }, {
      path: 'userNiubiInfo/:id',
      name: 'userNiubiInfo',
      meta: {
        title: '用户牛币变动详情',
        auth: true
      },
      component: _import('userManagement/userList/niubiList')
    }, {
      path: 'userCreditList',
      name: 'userCreditList',
      meta: {
        title: '用户赊账列表',
        auth: true
      },
      component: _import('userManagement/creditList/index')
    }, {
      path: 'virtualUser',
      name: 'virtualUser',
      meta: {
        title: '虚拟用户列表',
        auth: true
      },
      component: _import('userManagement/virtualUser/index')
    }, {
      path: 'virtualUserMessage',
      name: 'virtualUserMessage',
      meta: {
        title: '虚拟用户文案管理',
        auth: true
      },
      component: _import('userManagement/virtualUserMessage/index')
    }]
  }, {
    path: '/governmentAffairs/list',
    name: 'governmentAffairsList',
    meta: {
      title: '单位列表',
      auth: true
    },
    component: _import('governmentAffairs/list')
  }, {
    path: '/governmentAffairs/account',
    name: 'governmentAffairsAccount',
    meta: {
      title: '用户列表',
      auth: true
    },
    component: _import('governmentAffairs/account')
  }, {
    path: 'videoManagement',
    name: 'videoManagement',
    meta: {
      title: '视频管理',
      auth: true
    },
    component: _import('videoManagement/index'),
    children: [{
      path: 'merchantVideoList',
      name: 'merchantVideoList',
      meta: {
        title: '商家视频列表',
        auth: true
      },
      component: _import('videoManagement/merchantVideoList/index')
    }, {
      path: 'platformList',
      name: 'platformList',
      meta: {
        title: '平台视频列表',
        auth: true
      },
      component: _import('videoManagement/platformVideoList/index')
    }]
  }, {
    path: 'cityNews',
    name: 'cityNews',
    meta: {
      title: '同城新事',
      auth: true
    },
    component: _import('cityNews/index'),
    children: [{
      path: 'articleCategoryManagement',
      name: 'articleCategoryManagement',
      meta: {
        title: '文章分类管理',
        auth: true
      },
      component: _import('cityNews/articleCategoryManagement/index')
    }, {
      path: 'articleManagement',
      name: 'articleManagement',
      meta: {
        title: '文章管理',
        auth: true
      },
      component: _import('cityNews/articleManagement/index')
    }, {
      path: 'articleCommentList/:id',
      name: 'articleCommentList',
      meta: {
        title: '文章评论管理',
        auth: true
      },
      component: _import('cityNews/articleManagement/commentList')
    }]
  }, {
    path: 'dict',
    name: 'dict',
    meta: {
      title: '字典管理',
      auth: true
    },
    component: _import('dict/index'),
    children: [{
      path: 'dictList',
      name: 'dictList',
      meta: {
        title: '字典列表',
        auth: true
      },
      component: _import('dict/dictList/index')
    }, {
      path: 'dictDetail/:id',
      name: 'dictDetail',
      meta: {
        title: '字典详情',
        auth: true
      },
      component: _import('dict/dictList/dictDetail')
    }]
  }, {
    path: 'niubi',
    name: 'niubi',
    meta: {
      title: '牛币管理',
      auth: true
    },
    component: _import('niubiManagement/index'),
    children: [{
      path: 'niubiList',
      name: 'niubiList',
      meta: {
        title: '牛币明细列表',
        auth: true
      },
      component: _import('niubiManagement/list/index')
    }]
  },
  // 骑手
  {
    path: 'riderlist',
    name: 'rider',
    meta: {
      auth: true
    },
    component: _import('rider/index')
  },
  // 客服
  {
    path: 'service',
    name: 'service',
    meta: {
      auth: true
    },
    component: _import('service/index'),
    children: [{
      path: 'serviceList',
      name: 'serviceList',
      meta: {
        title: '客服列表',
        auth: true
      },
      component: _import('service/serviceList/index')
    }]
  },
  // 房屋论坛
  {
    path: 'houseFormu',
    name: 'houseFormu',
    meta: {
      auth: true
    },
    component: _import('houseFormu/index'),
    children: [{
      path: 'houseFormuApplyList',
      name: 'houseFormuApplyList',
      meta: {
        title: '房屋论坛列表',
        auth: true
      },
      component: _import('houseFormu/houseFormuList/index')
    }, {
      path: 'friendFormuApplyList',
      name: 'friendFormuApplyList',
      meta: {
        title: '交友论坛列表',
        auth: true
      },
      component: _import('houseFormu/friendFormuList/index')
    }, {
      path: 'workFormuApplyList',
      name: 'workFormuApplyList',
      meta: {
        title: '人才论坛列表',
        auth: true
      },
      component: _import('houseFormu/workFormuList/index')
    }, {
      path: 'carFormuApplyList',
      name: 'carFormuApplyList',
      meta: {
        title: '汽车论坛列表',
        auth: true
      },
      component: _import('houseFormu/carFormuList/index')
    }, {
      path: 'studyFormuApplyList',
      name: 'studyFormuApplyList',
      meta: {
        title: '学习乐园论坛列表',
        auth: true
      },
      component: _import('houseFormu/studyFormuList/index')
    }, {
      path: 'chapterList',
      name: 'chapterList',
      meta: {
        title: '经典视频小说列表',
        auth: true
      },
      component: _import('houseFormu/studyFormuList/chapterList')
    }, {
      path: 'lifeFormuApplyList',
      name: 'lifeFormuApplyList',
      meta: {
        title: '本地生活论坛列表',
        auth: true
      },
      component: _import('houseFormu/lifeFormuList/index')
    }, {
      path: 'affairsFormuApplyList',
      name: 'affairsFormuApplyList',
      meta: {
        title: '同城政务论坛列表',
        auth: true
      },
      component: _import('houseFormu/localAffairs/index')
    }, {
      path: 'affairsApplyList',
      name: 'affairsApplyList',
      meta: {
        title: '政务人员申请坛列表',
        auth: true
      },
      component: _import('houseFormu/affairApply/index')
    }]
  }, {
    path: 'appupdateList',
    name: 'appupdateList',
    meta: {
      title: 'app更新记录列表',
      auth: true
    },
    component: _import('appUpdate/index')
  }, {
    path: 'announcementList',
    name: 'announcementList',
    meta: {
      title: '消息公告管理',
      auth: true
    },
    component: _import('announcement/index')
  },
  // 系统 前端日志
  {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}];

/**
 * 在主框架之外显示
 */
var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}];

/**
 * 错误页面
 */
var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);