import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-dialog", {
    attrs: {
      title: _vm.title,
      visible: _vm.isVisible,
      "close-on-click-modal": false,
      "before-close": _vm.handleClose,
      width: "700px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "formData",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "标题",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请填写",
      readonly: _vm.title == "详情" ? true : false
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _vm.title == "新增" ? _c("el-form-item", {
    attrs: {
      label: "论坛分类",
      prop: "categoryId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.formData.categoryId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "categoryId", $$v);
      },
      expression: "formData.categoryId"
    }
  }, _vm._l(_vm.select, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm.title == "新增" ? _c("el-form-item", {
    attrs: {
      label: "城市"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择省"
    },
    on: {
      change: _vm.getCity
    },
    model: {
      value: _vm.province,
      callback: function callback($$v) {
        _vm.province = $$v;
      },
      expression: "province"
    }
  }, _vm._l(_vm.arr, function (item) {
    return _c("el-option", {
      key: item.ssqid,
      attrs: {
        value: item.ssqname
      }
    }, [_vm._v(_vm._s(item.ssqname))]);
  }), 1), _c("el-select", {
    attrs: {
      placeholder: "请选择市"
    },
    on: {
      change: _vm.getCountry
    },
    model: {
      value: _vm.city,
      callback: function callback($$v) {
        _vm.city = $$v;
      },
      expression: "city"
    }
  }, _vm._l(_vm.cityArr, function (item) {
    return _c("el-option", {
      key: item.ssqid,
      attrs: {
        value: item.ssqname
      }
    }, [_vm._v(_vm._s(item.ssqname))]);
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "内容",
      prop: "content"
    }
  }, [_c("el-input", {
    attrs: {
      readonly: _vm.title == "详情" ? true : false,
      placeholder: "请输入内容...",
      type: "textarea",
      maxlength: 100,
      rows: 4
    },
    model: {
      value: _vm.formData.content,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "content", $$v);
      },
      expression: "formData.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "文件类型",
      prop: "fileType"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.checkFileType
    },
    model: {
      value: _vm.formData.fileType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "fileType", $$v);
      },
      expression: "formData.fileType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "图片",
      value: 1
    }
  }), _c("el-option", {
    attrs: {
      label: "视频",
      value: 2
    }
  })], 1)], 1), _vm.formData.fileType == 1 ? _c("el-form-item", {
    attrs: {
      label: "",
      prop: "files"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    class: {
      hide: _vm.isHide
    },
    attrs: {
      action: _vm.imageVideo,
      "file-list": _vm.fileList,
      "list-type": "picture-card",
      "auto-upload": true,
      "before-upload": _vm.beforeupload,
      "on-success": _vm.handleAvatarSuccess,
      "on-remove": _vm.handleRemove,
      accept: "image/jpeg,image/jpg,image/png",
      "on-preview": _vm.handlePictureCardPreview,
      "on-change": _vm.fileChange,
      limit: 9
    },
    model: {
      value: _vm.formData.files,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "files", $$v);
      },
      expression: "formData.files"
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]), _c("el-dialog", {
    attrs: {
      "append-to-body": "",
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.dialogImageUrl,
      alt: ""
    }
  })])], 1) : _vm._e(), _vm.formData.fileType == 2 ? _c("el-form-item", {
    attrs: {
      label: "",
      prop: "files"
    }
  }, [_c("el-upload", {
    ref: "upload",
    class: {
      hide: _vm.isHide
    },
    attrs: {
      "file-list": _vm.fileList,
      "list-type": "picture-card",
      action: _vm.imageVideo,
      "auto-upload": true,
      "before-upload": _vm.beforeupload,
      "on-success": _vm.handleAvatarSuccess,
      accept: "video/mp4",
      "on-preview": _vm.handlePictureCardPreview,
      limit: 1
    },
    scopedSlots: _vm._u([{
      key: "file",
      fn: function fn(_ref) {
        var file = _ref.file;
        return _c("div", {}, [_c("video", {
          attrs: {
            src: file.url,
            controls: "",
            type: "video/mp4"
          }
        }), _c("span", {
          staticClass: "el-upload-list__item-actions"
        }, [_c("span", {
          staticClass: "el-upload-list__item-preview",
          on: {
            click: function click($event) {
              return _vm.handlePictureCardPreview(file);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-zoom-in"
        })]), !_vm.disabled ? _c("span", {
          staticClass: "el-upload-list__item-delete",
          on: {
            click: function click($event) {
              return _vm.handleRemove2(file);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-delete"
        })]) : _vm._e()])]);
      }
    }], null, false, 2349479162),
    model: {
      value: _vm.formData.files,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "files", $$v);
      },
      expression: "formData.files"
    }
  }, [_c("i", {
    staticClass: "el-icon-plus",
    attrs: {
      slot: "default"
    },
    slot: "default"
  })]), _c("el-dialog", {
    attrs: {
      "append-to-body": "",
      visible: _vm.dialogVisible,
      width: "400px",
      title: "视频查看",
      "before-close": _vm.videoClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("video", {
    ref: "vidoe",
    attrs: {
      width: "320",
      height: "240",
      src: _vm.dialogImageUrl,
      controls: "",
      type: "video/mp4"
    }
  })])], 1) : _vm._e()], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.title == "新增" ? _c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("取 消")]) : _vm._e(), _vm.title == "新增" ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v("确 定")]) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };