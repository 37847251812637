import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { Message, MessageBox } from 'element-ui';
import store from '@/store';
import util from '@/libs/util';

/**
 * @description 安全地解析 json 字符串
 * @param {String} jsonString 需要解析的 json 字符串
 * @param {String} defaultValue 默认值
 */
export function parse() {
  var jsonString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '{}';
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var result = defaultValue;
  try {
    result = JSON.parse(jsonString);
  } catch (error) {
    console.log(error);
  }
  return result;
}

// /**
//  * @description 接口请求返回
//  * @param {Any} data 返回值
//  * @param {String} msg 状态信息
//  * @param {Number} code 状态码
//  */
export function responseError(res) {
  if (res.code === 401) {
    // if (store.state.sysconfig.config.loginPopup === false) return
    // store.dispatch('sysconfig/config/setloginPopup', false)
    MessageBox.confirm('你的权限不足', '退出确认', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      store.dispatch('d2admin/account/logout', {
        confirm: false
      }, {
        root: true
      });
    });
  }
  if (res.code === 403) {
    MessageBox.confirm('你的登录已经过期', '退出确认', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      store.dispatch('d2admin/account/logout', {
        confirm: false
      }, {
        root: true
      });
    });
  } else {
    showMessage(res.message);
    errorCreate(res.message);
    return Promise.reject(new Error(res.message || 'Error'));
  }
  console.log(res, '=============2');
}
/**
 * @description 弹窗显示错误
 * @param {msg} msg 错误内容
 */
export function showMessage(msg) {
  Message({
    message: msg,
    type: 'error',
    duration: 5 * 1000
  });
}

/**
 * @description 记录和打印错误
 * @param {Error} error 错误对象
 */

export function errorLog(error) {
  // 添加到日志
  store.dispatch('d2admin/log/push', {
    message: '数据请求异常',
    type: 'danger',
    meta: {
      error: error
    }
  });
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>');
    console.log(error);
  }
}

/**
 * @description 创建一个错误
 * @param {String} msg 错误信息
 */
export function errorCreate(msg) {
  console.log(msg);
  var error = new Error(msg);
  errorLog(error);
  throw error;
}

/**
 * @description 将json转化为formdata
 * @param {Object} data 待转化的数据
 * @param {String} type 转化的类型，默认是json
 */
export function jsonToFormdata() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  //单层

  var form = new FormData();
  var kes = Object.keys(data);
  kes.forEach(function (item) {
    form.append(item, data[item]);
  });
  return form;
}