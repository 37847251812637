import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "角色路由权限",
      visible: _vm.roleRouteVisible,
      width: "60%",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.roleRouteVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "add-role-form-warp"
  }, [_c("el-form", {
    ref: "addRoleRouteForm",
    staticClass: "add-role-form",
    attrs: {
      model: _vm.addRoleRouteForm,
      "label-width": "80px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "角色名称"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.addRoleRouteForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.addRoleRouteForm, "name", $$v);
      },
      expression: "addRoleRouteForm.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "路由权限"
    }
  }, [_c("el-checkbox", {
    attrs: {
      "true-label": 1,
      "false-label": 2
    },
    on: {
      change: _vm.allSelect
    }
  }, [_vm._v("全选")]), _c("el-checkbox-group", {
    model: {
      value: _vm.checkList,
      callback: function callback($$v) {
        _vm.checkList = $$v;
      },
      expression: "checkList"
    }
  }, _vm._l(_vm.routeData, function (item) {
    return _c("el-checkbox", {
      key: item.id,
      attrs: {
        label: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.roleRouteVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("确 定")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };