import request from '../request/index';
import { jsonToFormdata } from '../request/tools';

// 分账列表
export var divAccountlist = function divAccountlist() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
  data = jsonToFormdata(data, type);
  //   console.log(data)
  return request({
    url: '/order/split/record/list',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
// 手动分账
export var handSplit = function handSplit() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
  data = jsonToFormdata(data, type);
  //   console.log(data)
  return request({
    url: '/order/split/record/split',
    method: 'post',
    data: data
  });
};

// 商家分类列表
export var merchantclassification = function merchantclassification() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
  data = jsonToFormdata(data, type);
  // console.log("innner",data)
  return request({
    url: '/shop/category/list',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

// 添加商家分类 
export var addClassification = function addClassification() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
  if (data.categoryType === '1' || data.categoryType === "1") {
    data.categoryType = 1;
  }
  if (data.categoryType === '2' || data.categoryType === "2") {
    data.categoryType = 2;
  }
  console.log("innner", data);
  return request({
    url: '/shop/category/add',
    method: 'post',
    data: data
  });
};

// 修改商家分类信息  注意 put和post方式的数据都是data delete是直接在请求路径上携带id便可
export var editClassification = function editClassification() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
  console.log("innner", data);
  return request({
    url: '/shop/category/edit',
    method: 'put',
    data: data
  });
};
// 修改商家分类信息 
export var editIshide = function editIshide(id) {
  return request({
    url: 'shop/category/change/' + id,
    method: 'put'
  });
};

// 删除商家 信息 
export var delMerchant = function delMerchant(id) {
  return request({
    url: 'shop/category/del/' + id,
    method: 'delete'
  });
};

//获取关键字列表 formData
export var getKeyWord = function getKeyWord() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var formData = jsonToFormdata(data);
  // data.categoryId=1
  // console.log(data, 'getKeyWord')
  return request({
    url: '/shop/category/keywords/list',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//新增二级菜单列表 json
export var saveAddKey = function saveAddKey() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/shop/category/keywords/add',
    method: 'post',
    data: data
  });
};

//删除二级菜单列表  
export var delAddKey = function delAddKey(id) {
  return request({
    url: '/shop/category/keywords/del/' + id,
    method: 'delete'
  });
};

//修改二级菜单列表 formData  关键字修改为了二级分类
export var saveEditKey = function saveEditKey() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/shop/category/keywords/edit',
    method: 'put',
    data: data
  });
};