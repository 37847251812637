import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d2-badge"
  }, _vm._l(_vm.badges, function (group, groupIndex) {
    return _c("p", {
      key: groupIndex,
      attrs: {
        align: "center"
      }
    }, _vm._l(group, function (badge, badgeIndex) {
      return _c("a", {
        key: badgeIndex,
        attrs: {
          href: badge.link,
          target: "_blank"
        }
      }, [_c("img", {
        attrs: {
          src: badge.img
        }
      })]);
    }), 0);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };