var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-button", {
    staticClass: "d2-mr btn-text can-hover",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.handleClick
    }
  }, [_c("d2-icon", {
    staticStyle: {
      "font-size": "18px"
    },
    attrs: {
      name: "search"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };