import request from '../request/index';
import { jsonToFormdata } from '../request/tools';

// 获取字典data列表
export var order = function order() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/statistics/order',
    method: 'post',
    data: data
  });
};
export var orderStatics = function orderStatics() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/statistics/orderStatics',
    method: 'post',
    data: data
  });
};
export var adminStatics = function adminStatics() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumOrder/list/to/admin',
    method: 'post',
    data: data
  });
};