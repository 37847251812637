export default {
  props: ['page'],
  data: function data() {
    return {
      // 页码参数
      // page: {
      //   pageNum: 1,
      //   pageSize: 10,
      //   total:0
      // }
    };
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      this.page.pageSize = val;
      this.$emit("pageChange", this.page);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page.pageNum = val;
      this.$emit("pageChange", this.page);
    }
  }
};