import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import d2Container from './d2-container';

// 注意 有些组件使用异步加载会有影响
Vue.component('d2-container', d2Container);
Vue.component('d2-icon', function () {
  return import('./d2-icon');
});
Vue.component('d2-icon-svg', function () {
  return import('./d2-icon-svg/index.vue');
});