var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "pad20"
  }, [_c("div", [_c("el-date-picker", {
    staticClass: "form-find-w",
    attrs: {
      size: "small",
      type: "date",
      format: "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd",
      placeholder: "开始时间",
      "picker-options": _vm.pickerOptions
    },
    model: {
      value: _vm.query.startDay,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "startDay", $$v);
      },
      expression: "query.startDay"
    }
  }), _c("el-date-picker", {
    staticClass: "form-find-w",
    attrs: {
      size: "small",
      type: "date",
      format: "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd",
      placeholder: "结束时间"
    },
    model: {
      value: _vm.query.endDay,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "endDay", $$v);
      },
      expression: "query.endDay"
    }
  }), _c("el-button", {
    attrs: {
      icon: "el-icon-search",
      type: "primary"
    },
    on: {
      click: _vm.handleCheck
    }
  }, [_vm._v("搜索")])], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      size: "medium",
      data: _vm.formdata,
      stripe: "",
      height: _vm.$clientHeight
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "days",
      align: "center",
      label: "创建时间"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [scope.row.days ? _c("div", [_vm._v(" " + _vm._s(scope.row.days))]) : _vm._e(), !scope.row.days ? _c("div", [_vm._v(" 合计")]) : _vm._e()])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "allPrice",
      align: "center",
      label: " 订单总金额"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "orderCount",
      align: "center",
      label: " 订单总量"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "sp",
      align: "center",
      label: "商品订单"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "kj",
      align: "center",
      label: "快捷订单"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "zj",
      align: "center",
      label: "直接支付订单"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "jd",
      align: "center",
      label: "酒店订单"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "hk",
      align: "center",
      label: "还款订单"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "nj",
      align: "center",
      label: "年金订单"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "gg",
      align: "center",
      label: "广告视频"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "bzj",
      align: "center",
      label: "保证金"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "qs",
      align: "center",
      label: "骑手入驻保证金"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "xcxwx",
      align: "center",
      label: "小程序微信支付"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "wx",
      align: "center",
      label: "微信支付"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "zfb",
      align: "center",
      label: "支付宝支付"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "zero",
      align: "center",
      label: "零元购"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "niubi",
      align: "center",
      label: "牛币支付"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "she",
      align: "center",
      label: "赊账支付"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "pressSend",
      align: "center",
      label: " 送货上门"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "shopSend",
      align: "center",
      label: " 店铺自取"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "riderSend",
      align: "center",
      label: " 骑手送达"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "refundCount",
      align: "center",
      label: " 退款订单数"
    }
  })], 1)], 1), _c("div", {
    staticClass: "tip-text"
  }, [_vm._v("注:列表未显示时间的则表示当天未发生任何交易数据")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };