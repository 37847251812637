import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.iterator.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm._l(_vm.options, function (item, index) {
    return [_vm.values.includes(item.value) ? [item.raw.listClass == "default" || item.raw.listClass == "" ? _c("span", {
      key: item.value,
      class: item.raw.cssClass,
      attrs: {
        index: index
      }
    }, [_vm._v(_vm._s(item.label + " "))]) : _c("el-tag", {
      key: item.value,
      class: item.raw.cssClass,
      attrs: {
        "disable-transitions": true,
        index: index,
        type: item.raw.listClass == "primary" ? "" : item.raw.listClass
      }
    }, [_vm._v(" " + _vm._s(item.label + " ") + " ")])] : _vm._e()];
  }), _vm.unmatch && _vm.showValue ? [_vm._v(" " + _vm._s(_vm._f("handleArray")(_vm.unmatchArray)) + " ")] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };