import request from '../request/index';

// 获取字典data列表
export var getWorkFormuList = function getWorkFormuList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumWorkInfo/applyList',
    method: 'post',
    data: data
  });
};

// 审核文章结果
export var editWorkFormu = function editWorkFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumWorkInfo/apply',
    method: 'post',
    data: data
  });
};

// 上架文章
export var uploadWorkFormu = function uploadWorkFormu() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/forumWorkInfo/changeOnline',
    method: 'post',
    data: data
  });
};