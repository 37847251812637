import request from '../request/index';
import { jsonToFormdata } from '../request/tools';

//获取骑手申请列表
export var getRiderList = function getRiderList() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data=jsonToFormdata(data)
  return request({
    url: '/rider/list',
    method: 'get',
    data: data
  });
};

//修改骑手申请 
export var editRider = function editRider() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data=jsonToFormdata(data)
  return request({
    url: '/rider/applyPass',
    method: 'post',
    data: data
  });
};

//修改骑手申请 
export var freeseRider = function freeseRider() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data=jsonToFormdata(data)
  return request({
    url: '/rider/edit',
    method: 'put',
    data: data
  });
};