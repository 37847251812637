import "E:\\code\\mn-admin\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "E:\\code\\mn-admin\\node_modules\\core-js\\modules\\es.promise.js";
import "E:\\code\\mn-admin\\node_modules\\core-js\\modules\\es.object.assign.js";
import "E:\\code\\mn-admin\\node_modules\\core-js\\modules\\es.promise.finally.js";
// Vue
import Vue from 'vue';
import App from './App';
// 核心插件
import d2Admin from '@/plugin/d2admin';
import UmyUi from 'umy-ui';
import 'umy-ui/lib/theme-chalk/index.css';
import VCharts from 'v-charts-v2';

// 字典标签组件
import DictTag from '@/components/DictTag';
// store
import store from '@/store/index';
// 菜单和路由设置
import router from './router';
import { menuHeader, menuAside } from '@/menu';
import { frameInRoutes } from '@/router/routes';

// import zpan from 'zpan_ui'
Vue.component('DictTag', DictTag);
// 核心插件
// Vue.use(zpan)
Vue.use(d2Admin);
Vue.use(UmyUi);
Vue.use(VCharts);
// Vue.use(VCharts)
Vue.prototype.$clientHeight = document.body.clientHeight - 270 + 'px';
Vue.prototype.$uploadImgurl = process.env.VUE_APP_UPLOADURL;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes);
    // 设置顶栏菜单
    this.$store.commit('d2admin/menu/headerSet', menuHeader);
    // 设置侧边栏菜单
    this.$store.commit('d2admin/menu/asideSet', menuAside);
    // 初始化菜单搜索功能
    this.$store.commit('d2admin/search/init', menuHeader);
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow');
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load');
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get');
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen');
  }
}).$mount('#app');