import request from '../request/index';
import { jsonToFormdata } from '../request/tools';
// 获取字典data列表
export var addTurntable = function addTurntable() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/turntable/add',
    method: 'post',
    data: data
  });
};
export var turntablelist = function turntablelist() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/turntable/listUser',
    method: 'get',
    data: data
  });
};
export var turntableEdit = function turntableEdit() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // console.log(data)
  return request({
    url: '/turntable/edit',
    method: 'post',
    data: data
  });
};
export var turntableDel = function turntableDel(id) {
  // console.log(data)
  return request({
    url: "/turntable/del/".concat(id),
    method: 'delete'
  });
};
export var editTurnTableStatus = function editTurnTableStatus() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "/turntable/editTurnTableStatus",
    method: 'post',
    data: data
  });
};
export var openTurnTable = function openTurnTable() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "/TurntableLotteryLottery/openTurnTable",
    method: 'post',
    data: data
  });
};
export var openTurnTableHistory = function openTurnTableHistory() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "TurntableLotteryLottery/openTurnTableHistory",
    method: 'post',
    data: data
  });
};
export var uploadVideo = function uploadVideo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "/turntable/uploadVideo",
    method: 'post',
    data: data
  });
};
export var getLottery = function getLottery() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: "/TurntableLotteryLottery/getLottery",
    method: 'post',
    data: data
  });
};
export var shareListAdmin = function shareListAdmin() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data = jsonToFormdata(data)
  return request({
    url: "/turntableShareHistory/listAdmin",
    method: 'get',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
export var shareApprove = function shareApprove() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data = jsonToFormdata(data)
  return request({
    url: "/turntableShareHistory/approve",
    method: 'post',
    data: data
  });
};
export var turntableLotteryLotteryEdit = function turntableLotteryLotteryEdit() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data = jsonToFormdata(data)
  return request({
    url: "/TurntableLotteryLottery/edit",
    method: 'post',
    data: data
  });
};
export var openTurnTableLevelFour = function openTurnTableLevelFour() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data = jsonToFormdata(data)
  return request({
    url: "/TurntableLotteryLottery/openTurnTableLevelFour",
    method: 'post',
    data: data
  });
};
export var openTurnTableLevelFourOneUser = function openTurnTableLevelFourOneUser() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // data = jsonToFormdata(data)
  return request({
    url: "/TurntableLotteryLottery/openTurnTableLevelFourOneUser",
    method: 'post',
    data: data
  });
};